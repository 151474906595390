import React, { memo, useState } from 'react'
import {
  Button,
  ButtonGroup,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import FacebookSquareIcon from 'Components/Icons/FacebookSquareIcon'
import InstagramSquareIcon from 'Components/Icons/InstagramSquareIcon'
import GmbIcon from 'Components/Icons/GmbIcon'
import TwitterSquareIcon from 'Components/Icons/TwitterSquareIcon'
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import moment from 'moment'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import FlatCard from 'Components/FlatCard'
import I18n from 'i18next'
import { useStyles } from 'Views/Statistics/Components/Styles/StatisticsView.css'
import withMuiPickersUtilsProvider from 'Hoc/WithMuiPickersUtilsProvider'
import PropTypes from 'prop-types'

const SocialMedia = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  GOOGLE_MY_BUSINESS: 'googlemybusiness',
  TWITTER: 'twitter'
}

const StatisticsView = ({
  statistics,
  startDate,
  endDate,
  updateStartDate,
  updateEndDate,
  loading,
  isFacebookLinked,
  isInstagramLinked,
  isGoogleLinked,
  isTwitterLinked
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const [type, setType] = useState('views')
  const [per, setPer] = useState('perDay')
  const [socialMedias, setSocialMedias] = useState([
    SocialMedia.FACEBOOK,
    SocialMedia.INSTAGRAM,
    SocialMedia.TWITTER,
    SocialMedia.GOOGLE_MY_BUSINESS
  ])

  /**
   *
   * @param statistics Array<Statistics>
   * @returns {Array<ChartData>|Array<ChartDataPerPost>}
   */
  const convertData = statistics => {
    switch (per) {
      case 'perDay':
        return convertDataPerDay(statistics)
      case 'perPost':
        return convertDataPerPost(statistics)
      default:
        return convertDataPerDay(statistics)
    }
  }

  /**
   *
   * @param statistics
   * @returns {Array<ChartDataPerPost>}
   */
  const convertDataPerPost = statistics => {
    const chartDatas = []
    statistics.forEach(statistic => {
      let chartData = chartDatas.find(
        chartData => chartData.id === statistic.menuboard.id
      )
      if (!chartData) {
        chartData = {
          id: statistic.menuboard.id,
          unixDate: moment(statistic.menuboard.createdAt).valueOf(),
          [SocialMedia.FACEBOOK]: 0,
          [SocialMedia.INSTAGRAM]: 0,
          [SocialMedia.GOOGLE_MY_BUSINESS]: 0,
          [SocialMedia.TWITTER]: 0
        }
        chartData[statistic.socialMedia] = statistic[type]
        chartDatas.push(chartData)
      } else {
        const previousStatistic = chartData[statistic.socialMedia]
          ? chartData[statistic.socialMedia]
          : 0
        chartData[statistic.socialMedia] = previousStatistic + statistic[type]
      }
    })
    return chartDatas.sort((a, b) => (a.unixDate > b.unixDate ? 1 : -1))
  }

  const convertDataPerDay = statistics => {
    const chartDatas = []
    statistics.forEach(statistic => {
      let chartData = chartDatas.find(chartData =>
        moment(chartData.unixDate).isSame(
          moment(statistic.date)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0),
          'day'
        )
      )
      if (!chartData) {
        chartData = {
          unixDate: moment(statistic.date)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .valueOf(),
          [SocialMedia.FACEBOOK]: 0,
          [SocialMedia.INSTAGRAM]: 0,
          [SocialMedia.GOOGLE_MY_BUSINESS]: 0,
          [SocialMedia.TWITTER]: 0
        }
        chartData[statistic.socialMedia] = statistic[type]

        chartDatas.push(chartData)
      } else {
        const previousStatistic = chartData[statistic.socialMedia]
          ? chartData[statistic.socialMedia]
          : 0
        chartData[statistic.socialMedia] = previousStatistic + statistic[type]
      }
    })
    return chartDatas.sort((a, b) => (a.unixDate > b.unixDate ? 1 : -1))
  }

  const getStrokeColor = socialMedia => {
    switch (socialMedia) {
      case SocialMedia.FACEBOOK:
        return theme.socialColors.facebookStats
      case SocialMedia.INSTAGRAM:
        return theme.socialColors.instagramStats
      case SocialMedia.GOOGLE_MY_BUSINESS:
        return theme.socialColors.googleMyBusiness
      case SocialMedia.TWITTER:
        return theme.socialColors.twitterStats
      default:
        return theme.socialColors.facebookStats
    }
  }
  const getFillColor = socialMedia => {
    switch (socialMedia) {
      case SocialMedia.FACEBOOK:
        return theme.socialColors.facebookStatsLight
      case SocialMedia.INSTAGRAM:
        return theme.socialColors.instagramStatsLight
      case SocialMedia.GOOGLE_MY_BUSINESS:
        return theme.socialColors.googleMyBusinessLight
      case SocialMedia.TWITTER:
        return theme.socialColors.twitterStatsLight
      default:
        return theme.socialColors.facebookStatsLight
    }
  }

  const getArea = socialMedia => {
    let area = (
      <Area
        key={socialMedia}
        type='monotone'
        dataKey={socialMedia}
        stackId='1'
        stroke={getStrokeColor(socialMedia)}
        fill={getFillColor(socialMedia)}
        name={I18n.t('form.' + socialMedia)}
      />
    )
    if (socialMedias.indexOf(socialMedia) === -1) {
      area = null
    }
    return area
  }

  const getBar = socialMedia => {
    let bar = (
      <Bar
        key={socialMedia}
        type='monotone'
        dataKey={socialMedia}
        stroke={getStrokeColor(socialMedia)}
        fill={getFillColor(socialMedia)}
        name={I18n.t('form.' + socialMedia)}
      />
    )
    if (socialMedias.indexOf(socialMedia) === -1) {
      bar = null
    }
    return bar
  }

  const getDisplay = socialMedia => {
    switch (per) {
      case 'perDay':
        return getArea(socialMedia)
      case 'perPost':
        return getBar(socialMedia)
      default:
        return getArea(socialMedia)
    }
  }

  const chartArea = () => {
    const facebookDisplay = getDisplay(SocialMedia.FACEBOOK)
    const instagramDisplay = getDisplay(SocialMedia.INSTAGRAM)
    const googlemybusinessDisplay = getDisplay(SocialMedia.GOOGLE_MY_BUSINESS)
    const twitterDisplay = getDisplay(SocialMedia.TWITTER)
    switch (type) {
      case 'views':
        return [
          facebookDisplay,
          instagramDisplay,
          googlemybusinessDisplay,
          twitterDisplay
        ]
      case 'likes':
        return [facebookDisplay, instagramDisplay, twitterDisplay]
      case 'comments':
        return [facebookDisplay, instagramDisplay, twitterDisplay]
      case 'shares':
        return [facebookDisplay, twitterDisplay]
      default:
        return [
          facebookDisplay,
          instagramDisplay,
          googlemybusinessDisplay,
          twitterDisplay
        ]
    }
  }

  const { total, lastMenuBoardTotal } = (() => {
    const total = {
      numberOfMenuBoard: 0,
      [SocialMedia.FACEBOOK]: 0,
      [SocialMedia.INSTAGRAM]: 0,
      [SocialMedia.GOOGLE_MY_BUSINESS]: 0,
      [SocialMedia.TWITTER]: 0
    }
    const datasPerPost = convertDataPerPost(statistics)
    const menuBoardIds = []
    datasPerPost.forEach(dataPerPost => {
      if (menuBoardIds.indexOf(dataPerPost.id) === -1) {
        menuBoardIds.push(dataPerPost.id)
      }
      socialMedias.forEach(socialMedia => {
        const statisticTotal = total[socialMedia] ? total[socialMedia] : 0
        const statisticLast = dataPerPost[socialMedia]
          ? dataPerPost[socialMedia]
          : 0
        total[socialMedia] = statisticTotal + statisticLast
      })
    })
    total.numberOfMenuBoard = menuBoardIds.length
    const lastMenuBoard = Math.max(...menuBoardIds)
    const lastMenuBoardTotal = {
      numberOfMenuBoard: 1
    }
    datasPerPost.forEach(dataPerPost => {
      if (dataPerPost.id === lastMenuBoard) {
        socialMedias.forEach(socialMedia => {
          const statisticTotal = lastMenuBoardTotal[socialMedia]
            ? lastMenuBoardTotal[socialMedia]
            : 0
          const statisticLast = dataPerPost[socialMedia]
            ? dataPerPost[socialMedia]
            : 0
          lastMenuBoardTotal[socialMedia] = statisticTotal + statisticLast
        })
      }
    })
    return { total, lastMenuBoardTotal }
  })()

  const pieDatas = data => {
    const pieDatas = []
    socialMedias.forEach(socialMedia => {
      pieDatas.push({
        name: I18n.t('form.' + socialMedia),
        value: data[socialMedia] ? data[socialMedia] : 0,
        socialMedia: socialMedia
      })
    })

    return pieDatas
  }

  const totalPieDatas = pieDatas(total)
  const lastMenuBoardTotalPieDatas = pieDatas(lastMenuBoardTotal)

  return (
    <CardContent className='statistics-view'>
      <Typography variant='h1' color='textSecondary' className={classes.title}>
        {I18n.t('views.statistics.title')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.actionContainer}>
            <Grid
              container
              spacing={2}
              alignItems={'center'}
              justifyContent={isMobile ? 'center' : 'flex-start'}
            >
              <Grid item>
                <ButtonGroup
                  color='primary'
                  className={classes.actionButtonGroup}
                >
                  <Button
                    variant={type === 'views' ? 'contained' : 'outlined'}
                    onClick={() => setType('views')}
                  >
                    {I18n.t('views.statistics.views')}
                  </Button>
                  <Button
                    variant={type === 'likes' ? 'contained' : 'outlined'}
                    onClick={() => setType('likes')}
                  >
                    {I18n.t('views.statistics.likes')}
                  </Button>
                  <Button
                    variant={type === 'comments' ? 'contained' : 'outlined'}
                    onClick={() => setType('comments')}
                  >
                    {I18n.t('views.statistics.comments')}
                  </Button>
                  <Button
                    variant={type === 'shares' ? 'contained' : 'outlined'}
                    onClick={() => setType('shares')}
                  >
                    {I18n.t('views.statistics.shares')}
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item className={classes.perContainer}>
                <ButtonGroup color='primary'>
                  <Button
                    variant={per === 'perDay' ? 'contained' : 'outlined'}
                    onClick={() => setPer('perDay')}
                  >
                    {I18n.t('views.statistics.perDay')}
                  </Button>
                  <Button
                    variant={per === 'perPost' ? 'contained' : 'outlined'}
                    onClick={() => setPer('perPost')}
                  >
                    {I18n.t('views.statistics.perPost')}
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item>
                <Grid container spacing={2} justifyContent={'center'}>
                  <Grid item>
                    <KeyboardDatePicker
                      label={I18n.t('form.startDate')}
                      value={startDate}
                      format='DD/MM/YYYY'
                      fullWidth
                      margin='normal'
                      minDateMessage={I18n.t('form.minDateError')}
                      cancelLabel={I18n.t('actions.cancel')}
                      onChange={date => {
                        if (date) {
                          updateStartDate(date.toDate())
                        }
                      }}
                      maxDate={endDate}
                    />
                  </Grid>
                  <Grid item>
                    <KeyboardDatePicker
                      label={I18n.t('form.endDate')}
                      value={endDate}
                      format='DD/MM/YYYY'
                      fullWidth
                      margin='normal'
                      minDateMessage={I18n.t('form.minDateError')}
                      cancelLabel={I18n.t('actions.cancel')}
                      onChange={date => {
                        if (date) {
                          updateEndDate(date.toDate())
                        }
                      }}
                      minDate={startDate}
                      maxDate={new Date()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FlatCard>
                <CardContent>
                  <Typography className={classes.totalTitle}>
                    {I18n.t('views.statistics.total', { startDate, endDate })}
                  </Typography>
                </CardContent>
              </FlatCard>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FlatCard>
                    <CardContent>
                      <Typography className={classes.totalLabel}>
                        {I18n.t('views.statistics.totalNumber', {
                          item: I18n.t('views.statistics.' + type, {
                            interpolation: { escapeValue: false }
                          }).toLowerCase()
                        })}
                      </Typography>
                      <Typography className={classes.totalValue}>
                        {(total[SocialMedia.FACEBOOK]
                          ? total[SocialMedia.FACEBOOK]
                          : 0) +
                          (total[SocialMedia.INSTAGRAM]
                            ? total[SocialMedia.INSTAGRAM]
                            : 0) +
                          (total[SocialMedia.GOOGLE_MY_BUSINESS]
                            ? total[SocialMedia.GOOGLE_MY_BUSINESS]
                            : 0) +
                          (total[SocialMedia.TWITTER]
                            ? total[SocialMedia.TWITTER]
                            : 0)}
                      </Typography>
                      <Typography className={classes.totalHelper}>
                        {I18n.t('views.statistics.duringPeriod')}
                      </Typography>
                    </CardContent>
                  </FlatCard>
                </Grid>
                <Grid item xs={12}>
                  <FlatCard>
                    <CardContent>
                      <Typography className={classes.totalLabel}>
                        {I18n.t('views.statistics.totalNumber', {
                          item: I18n.t('views.statistics.menu')
                        })}
                      </Typography>
                      <Typography className={classes.totalValue}>
                        {total.numberOfMenuBoard}
                      </Typography>
                      <Typography className={classes.totalHelper}>
                        {I18n.t('views.statistics.duringPeriod')}
                      </Typography>
                    </CardContent>
                  </FlatCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <FlatCard className={classes.pieChartContainer}>
                <ResponsiveContainer width='100%' height='100%'>
                  <PieChart>
                    <Pie
                      dataKey='value'
                      isAnimationActive
                      data={totalPieDatas}
                      cx='50%'
                      cy='50%'
                    >
                      {totalPieDatas.map(entries => (
                        <Cell
                          key={entries.socialMedia}
                          fill={getStrokeColor(entries.socialMedia)}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </FlatCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2} className={classes.alignHeight}>
            <Grid item xs={12}>
              <FlatCard>
                <CardContent>
                  <Typography className={classes.totalTitle}>
                    {I18n.t('views.statistics.lastMenuBoard')}
                  </Typography>
                </CardContent>
              </FlatCard>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FlatCard>
                    <CardContent>
                      <Typography className={classes.totalLabel}>
                        {I18n.t('views.statistics.lastMenuBoardTotal', {
                          item: I18n.t('views.statistics.' + type, {
                            interpolation: { escapeValue: false }
                          })
                        })}
                      </Typography>
                      <Typography className={classes.totalValue}>
                        {(lastMenuBoardTotal[SocialMedia.FACEBOOK]
                          ? lastMenuBoardTotal[SocialMedia.FACEBOOK]
                          : 0) +
                          (lastMenuBoardTotal[SocialMedia.INSTAGRAM]
                            ? lastMenuBoardTotal[SocialMedia.INSTAGRAM]
                            : 0) +
                          (lastMenuBoardTotal[SocialMedia.GOOGLE_MY_BUSINESS]
                            ? lastMenuBoardTotal[SocialMedia.GOOGLE_MY_BUSINESS]
                            : 0) +
                          (lastMenuBoardTotal[SocialMedia.TWITTER]
                            ? lastMenuBoardTotal[SocialMedia.TWITTER]
                            : 0)}
                      </Typography>
                      <Typography className={classes.totalHelper}>
                        {I18n.t('views.statistics.duringPeriod')}
                      </Typography>
                    </CardContent>
                  </FlatCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <FlatCard className={classes.pieChartContainer}>
                <ResponsiveContainer width='100%' height='100%'>
                  <PieChart>
                    <Pie
                      dataKey='value'
                      isAnimationActive
                      data={lastMenuBoardTotalPieDatas}
                      cx='50%'
                      cy='50%'
                      // outerRadius={80}
                      // fill={theme.palette.primary.main}
                      // label={renderCustomizedLabel}
                      // labelLine={false}
                    >
                      {lastMenuBoardTotalPieDatas.map(entries => (
                        <Cell
                          key={entries.socialMedia}
                          fill={getStrokeColor(entries.socialMedia)}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </FlatCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.graphParent}>
            {/* {loading && <Loader withLogo={false} />} */}
            <FlatCard className={classes.graphContainer}>
              <CardContent className={classes.pieChartContainer}>
                <Typography className={classes.graphTitle}>
                  {I18n.t('views.statistics.graphTitle', {
                    startDate,
                    endDate
                  })}
                </Typography>
                <ResponsiveContainer>
                  {per === 'perDay' ? (
                    <AreaChart
                      data={convertData(statistics)}
                      margin={{
                        left: isMobile ? -36 : 0
                        // bottom: isMobile ? -48 : 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis
                        dataKey='unixDate'
                        scale='time'
                        type='number'
                        domain={['dataMin', () => moment(endDate).valueOf()]}
                        tickFormatter={tick => moment(tick).format('DD/MM')}
                      />
                      <YAxis />
                      <Tooltip
                        labelFormatter={(label, _) =>
                          moment(label).format('DD/MM')
                        }
                      />
                      <Legend
                        wrapperStyle={{
                          left: isMobile ? -12 : 0
                        }}
                      />
                      {chartArea()}
                    </AreaChart>
                  ) : (
                    <BarChart
                      data={convertData(statistics)}
                      margin={{
                        left: isMobile ? -36 : 0
                        // bottom: isMobile ? -48 : 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis
                        dataKey='unixDate'
                        tickFormatter={tick =>
                          I18n.t('views.statistics.postName', {
                            date: moment(tick).toDate()
                          })
                        }
                      />
                      <YAxis />
                      <Tooltip
                        labelFormatter={(label, _) =>
                          I18n.t('views.statistics.postName', {
                            date: moment(label).toDate()
                          })
                        }
                      />
                      <Legend
                        wrapperStyle={{
                          left: isMobile ? -12 : 0
                        }}
                      />
                      {chartArea()}
                    </BarChart>
                  )}
                </ResponsiveContainer>
              </CardContent>
            </FlatCard>
            <div className={classes.socialContainer}>
              <ToggleButtonGroup
                orientation={isMobile ? 'horizontal' : 'vertical'}
                value={socialMedias}
                onChange={(_, value) => setSocialMedias(value)}
              >
                <ToggleButton
                  className={classes.iconButton}
                  value={SocialMedia.FACEBOOK}
                  disabled={!isFacebookLinked}
                >
                  <FacebookSquareIcon className={classes.icon} />
                </ToggleButton>
                <ToggleButton
                  className={classes.iconButton}
                  value={SocialMedia.INSTAGRAM}
                  disabled={!isInstagramLinked || type === 'shares'}
                >
                  <InstagramSquareIcon className={classes.icon} />
                </ToggleButton>
                <ToggleButton
                  className={classes.iconButton}
                  value={SocialMedia.GOOGLE_MY_BUSINESS}
                  disabled={
                    !isGoogleLinked ||
                    type === 'likes' ||
                    type === 'shares' ||
                    type === 'comments'
                  }
                >
                  <GmbIcon className={classes.icon} />
                </ToggleButton>
                <ToggleButton
                  className={classes.iconButton}
                  value={SocialMedia.TWITTER}
                  disabled={!isTwitterLinked}
                >
                  <TwitterSquareIcon className={classes.icon} />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </Grid>
      </Grid>
    </CardContent>
  )
}

StatisticsView.propTypes = {
  statistics: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  updateStartDate: PropTypes.func,
  updateEndDate: PropTypes.func,
  loading: PropTypes.bool,
  isFacebookLinked: PropTypes.bool,
  isInstagramLinked: PropTypes.bool,
  isGoogleLinked: PropTypes.bool,
  isTwitterLinked: PropTypes.bool
}

export default withMuiPickersUtilsProvider(memo(StatisticsView))
