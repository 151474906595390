import { createResource } from 'redux-rest-resource'
import { baseURL } from './'
import { cloneDeep } from 'lodash'

export const { types, actions, rootReducer } = createResource({
  name: 'gaming',
  url: `${baseURL}`,
  credentials: 'include',
  actions: {
    setRestaurantOwner: {
      isPure: true,
      reduce: (state, { context: restaurantOwner }) => {
        return {
          ...cloneDeep(state),
          restaurantOwner
        }
      }
    },
    enable: {
      method: 'PUT',
      url: './establishment/:id/gaming/enable'
    },
    disable: {
      method: 'DELETE',
      url: './establishment/:id/gaming/disable'
    }
  }
})
