import { createResource } from 'redux-rest-resource'
import { baseURL } from './'
import { cloneDeep, includes, map, remove } from 'lodash'

const establishmentReducer = (
  { items, ...state },
  { body: establishment, status }
) => {
  // Si la requête est terminée
  if (status === 'resolved') {
    items = map(items, item => {
      const establishments = item.establishments.map(item => {
        if (item.id === establishment.id) {
          console.log('catch')
          return establishment
        } else {
          return item
        }
      })
      return {
        ...item,
        establishments
      }
    })
  }

  return { ...state, items }
}

export const { types, actions, rootReducer } = createResource({
  name: 'restaurantOwner',
  url: `${baseURL}`,
  credentials: 'include',
  actions: {
    set: {
      isPure: true,
      reduce: (state, { context: restaurantOwner }) => {
        const restaurantOwners = state.items
        const restaurantOwnerIndex = restaurantOwners.findIndex(
          currentRestaurantOwner =>
            currentRestaurantOwner.id === restaurantOwner.id
        )
        if (restaurantOwnerIndex) {
          restaurantOwners[restaurantOwnerIndex] = restaurantOwner
        }
        return {
          ...cloneDeep(state),
          items: restaurantOwners
        }
      }
    },
    get: {
      method: 'GET',
      gerundName: 'getting',
      url: './restaurant_owner/:id'
    },
    fetch: { method: 'GET', gerundName: 'fetching', url: './restaurant_owner' },
    fetchMenuBoards: {
      method: 'GET',
      gerundName: 'fetchingMenuBoard',
      url: './restaurant_owner/:id/menuboards',
      reduce: (state, { body: menuboards, status }) => ({
        ...state,
        isFetchingMenuboards: status === 'pending',
        menuboards: status === 'resolved' ? menuboards : state.menuboards || []
      })
    },
    deleteMany: {
      isArray: true,
      alias: 'delete',
      method: 'DELETE',
      gerundName: 'deleting',
      url: './restaurant_owner/delete',
      reduce: ({ items, archives, ...state }, { context: deleted, status }) => {
        const isDeleting = status === 'pending'

        if (status === 'resolved') {
          // Suppression des données archivées du store
          remove(items, ({ id }) => includes(deleted, id))
        }

        return {
          ...state,
          items: cloneDeep(items),
          isDeleting
        }
      }
    },
    deleteSubscription: {
      method: 'DELETE',
      gerundName: 'deleting',
      url: './restaurant_owner/:id/stripe-subscription/delete',
      reduce: ({ items, ...state }, { body: updatedItem, status }) => {
        // Si la requête est terminée
        if (status === 'resolved') {
          // Mise à jour du local item
          items = map(items, item => {
            if (item.id === updatedItem.id) {
              return updatedItem
            } else {
              return item
            }
          })
        }

        return { ...state, items }
      }
    },
    forceEnableSubscription: {
      method: 'POST',
      gerundName: 'enabling',
      url: './establishment/:id/force-enable',
      reduce: establishmentReducer
    },
    forceDisableSubscription: {
      method: 'POST',
      gerundName: 'disabling',
      url: './establishment/:id/force-disable',
      reduce: establishmentReducer
    },
    export: {
      method: 'GET',
      gerundName: 'exporting',
      url: './restaurant_owner/export'
    }
  }
})
