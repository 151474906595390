import I18n from 'i18next'
import { channel } from 'redux-saga'
import { includes, defaultTo } from 'lodash'
import { call, put, take, select, delay } from 'redux-saga/effects'

// Actions
import StartupActions from 'Redux/StartupRedux'
import { NavigationActions } from '@react-navigation/core'

// Sagas
import { sessionExpired } from 'Sagas/AuthSagas'

// Resources
import { actions as apiActions } from 'Resources/ApiResource'
import {
  types as profileTypes,
  actions as profileActions
} from 'Resources/ProfileResource'
import { actions as langActions } from 'Resources/LangResource'

// I18n
import { configureI18nWithRedux } from 'I18n'

// Channels
const languageChannel = channel()

/**
 * Déclenchée au démarrage de l'application
 * @param action
 */
export function* startup(action) {
  // Récupération de l'état de l'API
  yield put(apiActions.getApi())
}

/**
 * L'API est disponible, on peut démarrer l'application normalement
 */
export function* apiAvailable() {
  yield put(profileActions.getProfile())
  yield take(
    ({ status, type }) =>
      includes([profileTypes.GET_PROFILE], type) && status !== 'pending'
  )

  // récupération des informations liées à la session
  const session = yield select(state => state.profile.item)

  // Fake waiting
  yield delay(1000)

  if (!session) {
    yield call(sessionExpired)
  }

  // Initialisation du store
  const config = {
    language: yield select(state => defaultTo(state.lang.language, null)),
    fallbackLng: 'fr',
    whitelist: ['en', 'fr']
  }

  yield put(langActions.initLang(config))

  // Récupération du store
  const { language, ...lang } = yield select(state => state.lang)

  // Changement de la langue
  configureI18nWithRedux({
    config,
    redux: {
      lookupRedux: () => ({ ...lang, language: null }),
      cacheUserLanguageRedux: language => {
        languageChannel.put(langActions.setLang(language))
      }
    }
  })

  I18n.changeLanguage(language)

  // Démarrage ok
  yield put(StartupActions.startupDone())
}

export function* watchLanguageChannel() {
  while (true) {
    const action = yield take(languageChannel)
    yield put(action)
  }
}

/**
 * L'api est indisponible, on affiche un message de maintenance
 */
export function* apiUnavailable(action) {
  // Démarrage erreur
  yield put(StartupActions.startupFailed(action))

  yield put(NavigationActions.navigate({ routeName: 'Exception' }))
}
