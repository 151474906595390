import { createResource } from 'redux-rest-resource'
import { baseURL } from './'
import { remove, includes, cloneDeep } from 'lodash'

export const { types, actions, rootReducer } = createResource({
  name: 'customer',
  url: `${baseURL}`,
  credentials: 'include',
  actions: {
    fetch: { method: 'GET', gerundName: 'fetching', url: './customer' },
    deleteMany: {
      isArray: true,
      alias: 'delete',
      method: 'DELETE',
      gerundName: 'deleting',
      url: './customer/delete',
      reduce: ({ items, archives, ...state }, { context: deleted, status }) => {
        const isDeleting = status === 'pending'

        if (status === 'resolved') {
          // Suppression des données archivées du store
          remove(items, ({ id }) => includes(deleted, id))
        }

        return {
          ...state,
          items: cloneDeep(items),
          isDeleting
        }
      }
    }
  }
})
