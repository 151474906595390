import { createResource } from 'redux-rest-resource'
import { baseURL } from './'
import { cloneDeep } from 'lodash'

export const { types, actions, rootReducer } = createResource({
  name: 'statistics',
  url: `${baseURL}`,
  credentials: 'include',
  actions: {
    fetch: {
      method: 'GET',
      gerundName: 'fetching',
      url:
        './establishment/:id/statistics?startDate=:startDate&endDate=:endDate',
      reduce: (state, { body, status }) => {
        return {
          ...state,
          items: status === 'resolved' ? body.statistics : state.items || []
        }
      }
    },
    setRestaurantOwner: {
      isPure: true,
      reduce: (state, { context: restaurantOwner }) => {
        return {
          ...cloneDeep(state),
          restaurantOwner
        }
      }
    },
    setStartDate: {
      isPure: true,
      reduce: (state, { context: date }) => {
        return {
          ...cloneDeep(state),
          startDate: date
        }
      }
    },
    setEndDate: {
      isPure: true,
      reduce: (state, { context: date }) => {
        return {
          ...cloneDeep(state),
          endDate: date
        }
      }
    },
    enable: {
      method: 'PUT',
      url: './establishment/:id/statistics/enable'
    },
    disable: {
      method: 'DELETE',
      url: './establishment/:id/statistics/disable'
    }
  }
})
