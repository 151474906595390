import React, { Component } from 'react'

// Libraries
import { Layout } from 'antd'
// import PropTypes from 'prop-types'

const { Footer } = Layout

class CustomFooter extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return <Footer />
  }
}

export default CustomFooter
