import React from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

const withMuiPickersUtilsProvider = ComposedComponent => {
  return props => {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale='fr'>
        <ComposedComponent {...props} />
      </MuiPickersUtilsProvider>
    )
  }
}

export default withMuiPickersUtilsProvider
