// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { defaultTo, get, isNil } from 'lodash'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
// Styles
import './Styles/CustomersScreen.less'
// Components
import { TableLayout } from 'Components'
// Ressources
import { actions as customerActions } from 'Resources/CustomerResource'
import { baseURL } from 'Resources'
import moment from 'moment'

const { confirm } = Modal

class CustomersScreen extends Component {
  static propTypes = {
    // Données de la vue
    customers: PropTypes.array,

    // Status de récupération des données
    isGathering: PropTypes.bool,

    // Actions d'API
    actions: PropTypes.object,

    // Boutons à afficher en haut à droite du tableau
    buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // Tableaux à afficher sur la page
    tables: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  }

  static defaultProps = {
    // Données de la vue
    customers: [],

    // Status de récupération des données
    isGathering: true,

    // Boutons à afficher en haut à droite du tableau
    buttons: [],

    // Tableaux à afficher sur la page
    tables: {
      customers: {
        multiDelete: {},
        rowKey: 'id',
        locale: {
          emptyText: I18n.t('pages.customers.table.empty')
        },
        pagination: {
          position: 'both',
          showSizeChanger: true
        },
        columns: {
          id: {
            title: 'pages.restaurantOwners.table.columns.id',
            dataIndex: 'id',
            key: 'id',
            render: id => (
              <span className='user-id'>
                {!isNil(id) ? id : I18n.t('common.unknown.male')}
              </span>
            ),
            sorter: (a, b) =>
              defaultTo(get(a, 'id'), 0) - defaultTo(get(b, 'id'), 0)
          },
          fullname: {
            title: 'pages.customers.table.columns.fullName',
            dataIndex: 'fullname',
            key: 'fullname',
            render: fullName => (
              <span className='user-fullName'>
                {!isNil(fullName) ? fullName : I18n.t('common.unknown.male')}
              </span>
            ),
            sorter: (a, b) =>
              defaultTo(get(a, 'fullname'), '').localeCompare(
                defaultTo(get(b, 'fullname'), '')
              )
          },
          email: {
            title: 'pages.customers.table.columns.email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) =>
              defaultTo(get(a, 'email'), '').localeCompare(
                defaultTo(get(b, 'email'), '')
              )
          },
          zipCode: {
            title: 'pages.customers.table.columns.zipCode',
            dataIndex: 'zipCode',
            key: 'zipCode',
            sorter: (a, b) =>
              defaultTo(get(a, 'zipCode'), '').localeCompare(
                defaultTo(get(b, 'zipCode'), '')
              )
          },
          createdAt: {
            title: 'pages.restaurantOwners.table.columns.createdAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: createdAt => (
              <span className='user-id'>
                {createdAt !== undefined
                  ? I18n.t('date.formats.longDay', {
                      date: moment(createdAt).toDate()
                    })
                  : '-'}
              </span>
            ),
            sorter: (a, b) =>
              moment(get(a, 'createdAt', 0)).toDate() -
              moment(get(b, 'createdAt', 0)).toDate()
          }
        }
      }
    }
  }

  constructor(props) {
    super(props)

    // États initiaux
    this.state = {}
  }

  componentDidMount = () => {
    // Récupération des données de la vue
    this.fetchCustomers()
  }

  /**
   * Récupère les clients
   */
  fetchCustomers = () => {
    this.props.actions
      .fetchCustomers()
      .catch(() => message.errors('api.errors.user.fetch'))
  }

  // onClickCustomer = customer => {
  //   this.setState({ customer, drawerOpened: true })
  // }

  // handleCloseDrawer = customer => {
  //   this.setState(
  //     {
  //       drawerOpened: false
  //     },
  //     () => unset(this.state, 'customer')
  //   )
  // }

  /**
   * Demande de suppression de clients
   */
  askDeleteCustomers = customers => {
    return new Promise((resolve, reject) => {
      confirm({
        title: I18n.t('pages.customers.components.modal.deleteCustomers.title'),
        content: I18n.t(
          'pages.customers.components.modal.deleteCustomers.content'
        ),
        okText: I18n.t('common.delete'),
        okType: 'danger',
        cancelText: I18n.t('common.cancel'),
        onOk: () => {
          this.deleteCustomers(customers)
          resolve()
        },
        onCancel: () => {
          reject(Error('cancel'))
        },
        maskClosable: true
      })
    })
  }

  /**
   * Suppression de clients
   */
  deleteCustomers = customerIds => {
    this.props.actions
      .deleteCustomers(customerIds)
      .then(() => {
        message.success(I18n.t(`api.success.customers.deleteMany`))
      })
      .catch(() => {
        message.error(I18n.t(`api.errors.customers.deleteMany`))
      })
  }

  render() {
    const { tables, buttons, isGathering, customers } = this.props
    // const { customer, drawerOpened } = this.state

    // Ajout des données aux tableaux
    customers.forEach(customer => {
      if (!isNil(customer.addresses) && customer.addresses.length > 0) {
        customer.zipCode = customer.addresses[0].zipCode
      }
    })
    tables.customers.rows = customers
    tables.customers.multiDelete.onClick = this.askDeleteCustomers

    tables.customers.multiDelete.title = count =>
      I18n.t('components.tableLayout.deleteMany', { count })

    // Ajout des actions au clic sur un ligne du tableau
    // tables.customers.onRow = (customer, id) => ({
    //   onClick: () => this.onClickCustomer(customer, id)
    // })

    return (
      <main className='customers screen'>
        {/* Tableau d'utilisateurs */}
        <TableLayout
          elementName='customer'
          elementKind='male'
          buttons={[
            ...buttons,
            {
              label: I18n.t('pages.restaurantOwners.table.actions.export'),
              icon: 'download',
              type: 'default',
              href: baseURL + '/customer/export'
            }
          ]}
          elementConsonant
          loading={isGathering}
          tables={tables}
        />

        {/* Affichage d'un utilisateur */}
        {/* <Drawer
          onClose={this.handleCloseDrawer}
          customer={customer}
          visible={drawerOpened}
        /> */}
      </main>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(CustomersScreen, 'defaultProps', {})

  return {
    customers: defaultTo(get(state, 'customer.items'), defaultProps.customers),
    isGathering: defaultTo(
      get(state, 'customer.isFetching'),
      defaultProps.isGathering
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...customerActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomersScreen)
