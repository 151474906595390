// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Form, Input, message, Icon } from 'antd'
import { get, mapValues, defaultTo, cloneDeep } from 'lodash'

// Components
import { Container } from 'Components/Modals'

// Styles
import './Styles/Modal.less'

const { Item: FormItem } = Form

class SampleFormModal extends Component {
  static propTypes = {
    form: PropTypes.object,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    title: PropTypes.string
  }

  static defaultProps = {
    visible: false,
    formData: {},
    onOk: () => {},
    onCancel: () => {},
    okText: I18n.t('components.modals.createArticle.okText'),
    title: I18n.t('components.modals.createArticle.title')
  }

  constructor(props) {
    super(props)

    // États initiaux
    this.state = {
      visible: get(props, 'visible'),
      loading: false
    }
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      visible: get(nextProps, 'visible')
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    const { form, onOk } = this.props

    // Validation du formulaire
    form.validateFieldsAndScroll((error, { formData }) => {
      if (!error) {
        message.success(I18n.t('api.success.example.submit'))
        onOk()
        form.resetFields()
      }
    })
  }

  _handleCancel = e => {
    const { onCancel, form } = this.props

    this.setState(
      {
        visible: false
      },
      () => {
        onCancel(e)
        form.resetFields()
      }
    )
  }

  render() {
    const { visible = false, loading = false } = this.state
    const { form, title, okText } = this.props
    const { getFieldDecorator } = form

    return (
      <Container
        title={title}
        visible={visible}
        loading={loading}
        onOk={this._handleSubmit}
        okText={okText}
        onCancel={this._handleCancel}
        className={'modal SampleForm'}
      >
        <Form onSubmit={this._handleSubmit}>
          {/* Example */}
          <FormItem label={I18n.t(`fields.example.title`)}>
            {getFieldDecorator('example', {
              rules: [
                {
                  required: true,
                  message: I18n.t(`fields.example.requiredMessage`)
                }
              ]
            })(
              <Input
                prefix={<Icon type={I18n.t(`fields.example.icon`)} />}
                placeholder={I18n.t(`fields.example.placeholder`)}
              />
            )}
          </FormItem>
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    mapPropsToFields(props) {
      const defaultProps = get(SampleFormModal, 'defaultProps', {})

      const formData = cloneDeep(
        defaultTo(get(props, 'formData'), defaultProps.formData)
      )

      return mapValues(formData, object => {
        return Form.createFormField({ value: object })
      })
    }
  })(SampleFormModal)
)
