import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Form, DatePicker, Divider } from 'antd'
import I18n from 'i18next'
import moment from 'moment'
import { isNil } from 'lodash'

const PromotionCodeEditForm = ({
  initialValues,
  onSubmit,
  form: { validateFields, getFieldDecorator, resetFields }
}) => {
  const handleSubmit = e => {
    e.preventDefault()

    validateFields((err, values) => {
      if (!err) {
        onSubmit(values)
        resetFields()
      }
    })
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label={I18n.t('forms.code')}>
        {getFieldDecorator('code', {
          initialValue: initialValues.code,
          rules: [{ required: true, message: I18n.t('formsValidation.code') }]
        })(<Input style={{ width: '100%' }} />)}
      </Form.Item>
      <Form.Item label={I18n.t('forms.freeDays')}>
        {getFieldDecorator('freeDays', {
          initialValue: initialValues.freeDays
        })(<Input style={{ width: '100%' }} />)}
      </Form.Item>
      <Divider>{I18n.t('common.or')}</Divider>
      <Form.Item label={I18n.t('forms.freeExpireAt')}>
        {getFieldDecorator('freeExpireAt', {
          initialValue: !isNil(initialValues.freeExpireAt)
            ? moment(initialValues.freeExpireAt)
            : undefined
        })(<DatePicker style={{ width: '100%' }} />)}
      </Form.Item>
      <Form.Item label={I18n.t('forms.expireAt')}>
        {getFieldDecorator('expireAt', {
          initialValue: !isNil(initialValues.expireAt)
            ? moment(initialValues.expireAt)
            : undefined
        })(<DatePicker style={{ width: '100%' }} />)}
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>
          {I18n.t('common.save')}
        </Button>
      </Form.Item>
    </Form>
  )
}

PromotionCodeEditForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  form: PropTypes.any
}

export default Form.create()(PromotionCodeEditForm)
