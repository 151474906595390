import React, { Component } from 'react'

// Libraries
import { get } from 'lodash'
import { Layout } from 'antd'
import PropTypes from 'prop-types'
import { SceneView } from '@react-navigation/core'

const { Content } = Layout

class UnauthenticatedLayout extends Component {
  static propTypes = {
    descriptors: PropTypes.object,
    navigation: PropTypes.object
  }

  static defaultProps = {
    descriptors: {}
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { descriptors, navigation } = this.props
    const currentScreen = get(
      navigation,
      `state.routes[${get(navigation, 'state.index')}]`
    )
    const descriptor = get(descriptors, currentScreen.key)

    return (
      <Layout>
        <Content>
          <SceneView
            component={descriptor.getComponent()}
            navigation={descriptor.navigation}
          />
        </Content>
      </Layout>
    )
  }
}

export default UnauthenticatedLayout
