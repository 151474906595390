// Libraries
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, isNil } from 'lodash'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Drawer, message } from 'antd'
// Common components
// Styles
// import './Styles/RestaurantOwnerDrawer.less'
// Ressources
import { actions as promotionCodeActions } from 'Resources/PromotionCodeResource'
import PromotionCodeEditForm from 'Forms/PromotionCodeEditForm'
import I18n from 'i18next'

class PromotionCodeDrawer extends Component {
  static propTypes = {
    // Données de la vue
    promotionCode: PropTypes.object,
    actions: PropTypes.object,

    // Fonctions
    onClose: PropTypes.func
  }

  static defaultProps = {
    // Données de la vue
    promotionCode: {}
  }

  constructor(props) {
    super(props)

    // États initiaux
    this.state = {
      visible: get(props, 'visible'),
      childrenDrawer: false,
      openedModal: ''
    }
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      visible: get(nextProps, 'visible')
    })
  }

  /**
   * Méthode pour cacher le drawer
   */
  handleClose = () => {
    const { onClose } = this.props

    this.setState({ visible: false }, onClose)
  }

  _update = values => {
    const { promotionCode } = this.props
    if (isNil(promotionCode.id)) {
      this.props.actions
        .addPromotionCode({
          ...values,
          freeExpireAt: values.freeExpireAt
            ? values.freeExpireAt.toDate()
            : undefined,
          expireAt: values.expireAt ? values.expireAt.toDate() : undefined
        })
        .then(() => {
          message.success(I18n.t(`api.success.promotionCode.add`))
          this.handleClose()
        })
        .catch(() => {
          message.error(I18n.t(`api.errors.promotionCode.update`))
        })
    } else {
      this.props.actions
        .updatePromotionCode({
          id: promotionCode.id,
          ...values,
          freeExpireAt: values.freeExpireAt
            ? values.freeExpireAt.toDate()
            : undefined,
          expireAt: values.expireAt ? values.expireAt.toDate() : undefined
        })
        .then(() => {
          message.success(I18n.t(`api.success.promotionCode.update`))
          this.handleClose()
        })
        .catch(() => {
          message.error(I18n.t(`api.errors.promotionCode.update`))
        })
    }
  }

  render() {
    const { promotionCode } = this.props
    const { visible } = this.state

    return (
      <Drawer
        width={900}
        placement='right'
        onClose={this.handleClose}
        visible={visible}
        className='restaurantOwner-profile'
      >
        {/* Informations complémentaires */}
        <main className='restaurantOwner-profile-description'>
          {/* Informations de l'établissement */}
          <section className='restaurantOwner-profile-description-group'>
            <PromotionCodeEditForm
              onSubmit={this._update}
              initialValues={{
                ...promotionCode
              }}
            />
          </section>
        </main>
      </Drawer>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...promotionCodeActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionCodeDrawer)
