import { put, select, takeLatest } from 'redux-saga/effects'
import {
  types as StatisticsTypes,
  actions as statisticsActions
} from 'Resources/StatisticsResource'
import { actions as restaurantOwnerActions } from 'Resources/RestaurantOwnerResource'

export function* statisticsSagas() {
  yield takeLatest(
    action =>
      action.type === StatisticsTypes.ENABLE_STATISTICS &&
      action.status === 'resolved',
    enableStatistics
  )
  yield takeLatest(
    action =>
      action.type === StatisticsTypes.DISABLE_STATISTICS &&
      action.status === 'resolved',
    disableStatistics
  )
}

function* enableStatistics(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions.push('api.statistics_access')
  yield put(statisticsActions.setRestaurantOwnerStatistics(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}

function* disableStatistics(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions = restaurantOwner.permissions.filter(
    permission => permission !== 'api.statistics_access'
  )
  yield put(statisticsActions.setRestaurantOwnerStatistics(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}
