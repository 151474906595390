import React, { Component } from 'react'
// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { Button, Icon, Input, Table } from 'antd'
import { withNavigation } from '@react-navigation/core'
import { defaultTo, get, has, includes, isEmpty, isFunction, map } from 'lodash'
// Styles
import './Styles/TableLayoutComponent.less'
// Helpers
import { searchData } from 'Helpers'

const { Search } = Input
const { Column, ColumnGroup } = Table

class TableLayout extends Component {
  static propTypes = {
    // Liste de boutons à afficher
    buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // Position où afficher les boutons ('bottom'/'top'/'both'/'none')
    buttonsPosition: PropTypes.oneOf(['bottom', 'top', 'both', 'none']),

    // Liste des tableaux à afficher
    tables: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // Chargement en cours
    loading: PropTypes.bool,

    // Navigation
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired
    }),

    // Permet de définir l'affiche du titre (par défaut l'affichage affichés/trouvés)
    titleEnabled: PropTypes.bool,

    // Permet de définir si la fonction de recherche est active
    searchEnabled: PropTypes.bool,

    // Permet de définir la position de la zone de recherche
    searchPosition: PropTypes.oneOf(['bottom', 'top', 'both', 'none']),

    // Overwrite du render header gauche ou droit
    renderHeaderLeft: PropTypes.func,
    renderHeaderRight: PropTypes.func,

    // Gestion du wording des éléments affichés/trouvés
    elementName: PropTypes.string,
    elementKind: PropTypes.oneOf(['male', 'female'])
  }

  static defaultProps = {
    buttons: {},
    buttonsPosition: 'top',
    tables: {},
    loading: false,
    titleEnabled: true,
    searchEnabled: true,
    searchPosition: 'top',
    elementKind: 'male'
  }

  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      filterText: ''
    }
  }

  /**
   * Filtre les données en fonction du motif renseigné dans le champs de recherche
   *
   * Retourne un tableau contenant toutes les données en fonction de la recherche de l'utilisateur
   * @return {array}
   */
  _filterData = (data, filteredProperties) => {
    let { searchText = '' } = this.state

    return searchData(data, searchText, filteredProperties)
  }

  _handleSearch = event => {
    const searchText = get(event, 'target.value', event)

    this.setState({ searchText })
  }

  _renderSearchBar = position => {
    const {
      searchEnabled,
      searchPosition,
      elementName,
      elementKind
    } = this.props
    const isRendered = includes(['both', position], searchPosition)

    return isRendered && searchEnabled ? (
      <Search
        placeholder={
          elementName
            ? I18n.t(`fields.search.placeholder.custom.${elementKind}`, {
                elementName: I18n.t(
                  `components.tableLayout.elements.${elementName}.singular`
                )
              })
            : I18n.t('fields.search.placeholder.default')
        }
        enterButton
        onSearch={this._handleSearch}
        onChange={this._handleSearch}
      />
    ) : (
      <div />
    )
  }

  _handleButtonClick = (action, routeName) => {
    if (isFunction(action)) {
      action()
    } else if (!isEmpty(routeName)) {
      this.props.navigation.navigate(routeName)
    }
  }

  _renderButton = (
    { label = '', icon, type = 'primary', action, routeName, href = undefined },
    index = 0
  ) => (
    <Button
      key={index}
      onClick={() => this._handleButtonClick(action, routeName)}
      type={type}
      icon={icon}
      href={href}
    >
      {label}
    </Button>
  )

  _renderButtons = position => {
    const { buttons, buttonsPosition } = this.props
    const isRendered = includes(['both', position], buttonsPosition)

    return (
      isRendered &&
      (!isEmpty(buttons) && (
        <div className='buttons'>
          {map(buttons, ({ render = this._renderButton, ...button }, index) =>
            render(button, index)
          )}
        </div>
      ))
    )
  }

  /**
   * Colonne avec l'attribut `searchable`
   */
  _searchableColumn = column => ({
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
      <div className='ant-table-search'>
        <Input
          ref={ref => (this.searchInput = ref)}
          placeholder={I18n.t('fields.search.placeholder.default')}
          onChange={event => {
            const filterText = get(event, 'target.value')

            if (isEmpty(filterText)) {
              clearFilters()
            } else {
              setSelectedKeys([filterText])
            }

            this.setState({ filterText })
          }}
          onPressEnter={confirm}
          allowClear
        />
        <Button type='primary' onClick={confirm} icon='search' />
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[get(column, 'dataIndex')]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    }
  })

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  /**
   * Zone pour les actions de lot
   */
  _renderFooter = (currentPageData, table, index) => {
    const { tablesSelections } = this.state
    const selectedItems = defaultTo(
      get(tablesSelections, `${index}.selectedRowKeys`),
      []
    )
    const length = get(selectedItems, 'length')

    return (
      <div className='actions'>
        <div className='actions-buttons'>
          <Button
            disabled={isEmpty(selectedItems)}
            onClick={() => {
              return get(table, 'multiDelete.onClick')(selectedItems).then(
                () => {
                  this.setState({ tablesSelections: {} })
                }
              )
            }}
            type='danger'
          >
            {has(table, 'multiDelete.title')
              ? get(table, 'multiDelete.title')(length)
              : I18n.t('components.tableLayout.deleteMany', { length })}
          </Button>
        </div>
      </div>
    )
  }

  _renderColumns = columns => {
    return map(columns, ({ title, ...column }, index) => {
      if (has(column, 'columns')) {
        const { columns, ...columnProps } = column

        return (
          <ColumnGroup title={I18n.t(title)} {...columnProps}>
            {this._renderColumns(get(column, 'columns'))}
          </ColumnGroup>
        )
      } else {
        // Gestion de la recherche sur cette colonne
        if (has(column, 'searchable')) {
          column = {
            ...column,
            ...this._searchableColumn(column)
          }
        }
        return <Column title={I18n.t(title)} {...column} key={index} />
      }
    })
  }

  handleSelectRows = (selectedRowKeys, selectedRows, table, index) => {
    this.setState(({ tablesSelections = {} }) => {
      tablesSelections[index] = {
        selectedRowKeys,
        selectedRows
      }

      return { tablesSelections }
    })
  }

  _renderTables = () => {
    const { searchText, tablesSelections } = this.state
    const {
      tables,
      loading,
      searchEnabled,
      titleEnabled,
      elementName,
      elementKind = 'male'
    } = this.props

    return map(tables, (table, index) => {
      let { columns, rows = [], multiDelete, ...props } = table
      const dataSource = searchEnabled
        ? this._filterData(rows, columns.filteredProperties)
        : rows
      const { length } = dataSource

      // Gestion de la multi suppression
      props.rowSelection = !isEmpty(multiDelete)
        ? {
            ...defaultTo(props.rowSelection, {
              selectedRowKeys: defaultTo(
                get(tablesSelections, `${index}.selectedRowKeys`),
                []
              )
            }),
            onChange: (selectedRowKeys, selectedRows) =>
              this.handleSelectRows(selectedRowKeys, selectedRows, table, index)
          }
        : props.rowSelection

      props.footer = !isEmpty(multiDelete)
        ? currentPageData => this._renderFooter(currentPageData, table, index)
        : props.footer

      // Gestion du message listant le nombre d'éléments affichés ou trouvés
      const titleSearch =
        searchEnabled &&
        (searchText
          ? // Éléments trouvés
            // Nom permettant de définir la nature des éléments
            elementName
            ? I18n.t(
                length > 1
                  ? `components.tableLayout.displayed-count.results.custom.${elementKind}.plural`
                  : `components.tableLayout.displayed-count.results.custom.${elementKind}.singular`,
                {
                  length,
                  elementName: I18n.t(
                    length > 1
                      ? `components.tableLayout.elements.${elementName}.plural`
                      : `components.tableLayout.elements.${elementName}.singular`
                  )
                }
              )
            : I18n.t(
                length > 1
                  ? 'components.tableLayout.displayed-count.results.default.plural'
                  : 'components.tableLayout.displayed-count.results.default.singular',
                { length }
              )
          : // Éléments affichés
          // Nom permettant de définir la nature des éléments
          elementName
          ? I18n.t(
              length > 1
                ? `components.tableLayout.displayed-count.elements.custom.${elementKind}.plural`
                : `components.tableLayout.displayed-count.elements.custom.${elementKind}.singular`,
              {
                length,
                elementName: I18n.t(
                  length > 1
                    ? `components.tableLayout.elements.${elementName}.plural`
                    : `components.tableLayout.elements.${elementName}.singular`
                )
              }
            )
          : I18n.t(
              length > 1
                ? 'components.tableLayout.displayed-count.elements.default.plural'
                : 'components.tableLayout.displayed-count.elements.default.singular',
              { length }
            ))

      const title = titleEnabled && <p>{titleSearch}</p>

      return (
        <div key={index} className='table-layout-table'>
          <Table
            title={() => title}
            dataSource={dataSource}
            loading={loading}
            {...props}
          >
            {this._renderColumns(columns)}
          </Table>
        </div>
      )
    })
  }

  render() {
    const {
      renderHeaderLeft = () => this._renderSearchBar('top'),
      renderHeaderRight = () => this._renderButtons('top'),
      searchPosition,
      buttons
    } = this.props

    return (
      <div className='component table-layout'>
        <header>
          {/* Contenu gauche (Default: Barre de recherche) */}
          {renderHeaderLeft()}

          {/* Contenu droite (Default: Boutons) */}
          {renderHeaderRight()}
        </header>

        <main>
          {/* Tableaux */}
          {this._renderTables()}
        </main>

        <footer
          className={
            isEmpty(buttons) && !includes(['both', 'bottom'], searchPosition)
              ? 'hidden'
              : 'visible'
          }
        >
          {/* Barre de recherche */}
          {this._renderSearchBar('bottom')}

          {/* Boutons */}
          {this._renderButtons('bottom')}
        </footer>
      </div>
    )
  }
}

export default withNavigation(TableLayout)
