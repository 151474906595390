import { get } from 'lodash'
import WebSocketActions, { WebSocketTypes } from 'WebSocket/Redux'

const WebSocketMiddleware = (function() {
  // Vidage de la socket
  let socket = {}

  const onOpen = (
    store,
    name = 'default',
    address = '',
    protocols = [],
    options = {},
    types
  ) => {
    // Notification de connexion
    store.dispatch(
      WebSocketActions.wsConnected(name, address, protocols, options, types)
    )
  }

  const onClose = (store, name, event) => {
    // Notification de déconnexion
    store.dispatch(WebSocketActions.wsDisconnected(name, event))
  }

  const onError = (store, name, details) => {
    // Notification d'erreur
    store.dispatch(WebSocketActions.wsError(name, { details }))
  }

  const onMessage = (store, name, content) => {
    store.dispatch(WebSocketActions.wsMessageReceived(name, content))
  }

  return store => next => action => {
    // Récupération du nom de la socket
    let name = get(action, 'name', 'default')
    let type = get(action, 'type', '')

    switch (type) {
      // Demande de connexion
      case WebSocketTypes.WS_CONNECT:
        // Récupération des paramètres de connexion
        const {
          address = 'localhost',
          protocols = [],
          options = {},
          types
        } = action

        // Fermeture de la connexion
        // Ouverture d'une nouvelle connexion
        if (get(socket, name, false)) {
          socket[name].close()
        }

        // Notification de la connexion en cours
        store.dispatch(
          WebSocketActions.wsConnecting(
            name,
            address,
            protocols,
            options,
            types
          )
        )

        // Tentative de connexion
        socket[name] = new WebSocket(address, protocols, options)

        // Surveillance de l’événement de réception d'un message
        socket[name].onmessage = ({ data }) => {
          onMessage(store, name, data)
        }

        // Surveillance de l’événement d'erreur
        socket[name].onerror = details => {
          onError(store, name, details)
        }

        // Surveillance de l’événement de fermeture de connexion
        socket[name].onclose = event => {
          // Notification de déconnexion
          onClose(store, name, event)

          // Reconnexion à la websocket
          store.dispatch(
            WebSocketActions.wsConnect(name, address, protocols, options)
          )
        }

        // Surveillance de l’événement d'ouverture de connexion
        socket[name].onopen = event => {
          onOpen(store, name, address, protocols, options, types)
        }

        break

      // Demande de déconnexion
      case WebSocketTypes.WS_DISCONNECT:
        // Notification de la déconnexion en cours
        store.dispatch(WebSocketActions.wsDisconnecting(name))

        // Fermeture de la connexion
        if (get(socket, name)) {
          socket[name].close()
        }

        // Vidage de la socket
        socket[name] = false

        // Notification de déconnexion
        store.dispatch(WebSocketActions.wsDisconnected(name))

        break

      // Envoi d'un message depuis la socket
      case WebSocketTypes.WS_SEND:
        if (get(action, 'content')) {
          // Envoi à WebSocket.send()
          socket[name].send(JSON.stringify(action.content))

          // Notification d'envoi
          store.dispatch(WebSocketActions.wsMessageSent(name, action.content))
        }

        break

      // Passage à l'action suivante
      default:
        return next(action)
    }
  }
})()

export default WebSocketMiddleware
