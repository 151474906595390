import { put, select, takeLatest } from 'redux-saga/effects'
import {
  types as OrderTypes,
  actions as orderActions
} from 'Resources/OrderResource'
import { actions as restaurantOwnerActions } from 'Resources/RestaurantOwnerResource'

export function* orderSagas() {
  yield takeLatest(
    action =>
      action.type === OrderTypes.ENABLE_ORDER && action.status === 'resolved',
    enableOrder
  )
  yield takeLatest(
    action =>
      action.type === OrderTypes.DISABLE_ORDER && action.status === 'resolved',
    disableOrder
  )
}

function* enableOrder(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions.push('api.order_access')
  yield put(orderActions.setRestaurantOwnerOrder(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}

function* disableOrder(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions = restaurantOwner.permissions.filter(
    permission => permission !== 'api.order_access'
  )
  yield put(orderActions.setRestaurantOwnerOrder(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}
