import { put, select, takeLatest } from 'redux-saga/effects'
import {
  types as EventsTypes,
  actions as eventsActions
} from 'Resources/EventsResource'
import { actions as restaurantOwnerActions } from 'Resources/RestaurantOwnerResource'

export function* eventsSagas() {
  yield takeLatest(
    action =>
      action.type === EventsTypes.ENABLE_EVENTS && action.status === 'resolved',
    enableEvents
  )
  yield takeLatest(
    action =>
      action.type === EventsTypes.DISABLE_EVENTS &&
      action.status === 'resolved',
    disableEvents
  )
}

function* enableEvents(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions.push('api.events_access')
  yield put(eventsActions.setRestaurantOwnerEvents(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}

function* disableEvents(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions = restaurantOwner.permissions.filter(
    permission => permission !== 'api.events_access'
  )
  yield put(eventsActions.setRestaurantOwnerEvents(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}
