import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme =>
  createStyles({
    icon: {
      padding: theme.spacing(0.5),
      backgroundColor: '#1da1f2',
      borderRadius: 6
    }
  })
)
