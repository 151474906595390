import { createResource } from 'redux-rest-resource'
import { defaultTo, cloneDeep } from 'lodash'
import { baseURL } from './'

const defaultList = ['fr', 'en']

export const { types, actions, rootReducer } = createResource({
  name: 'lang',
  url: `${baseURL}`,
  actions: {
    // Initialisation des langues
    init: {
      isPure: true,
      reduce: (
        state,
        { context: { language, whitelist = defaultList, fallbackLng } }
      ) => ({
        language,
        whitelist,
        fallbackLng: defaultTo(fallbackLng, whitelist[0])
      })
    },

    // Changement de la langue actuelle
    set: {
      isPure: true,
      reduce: (state, { context: language = defaultList[0] }) => {
        localStorage.setItem('i18nextLng', language)

        return {
          ...cloneDeep(state),
          language
        }
      }
    }
  }
})
