// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { Carousel, Icon } from 'antd'
import { defaultTo, get, map, orderBy, remove } from 'lodash'
import { bindActionCreators } from 'redux'
// Components
import { Container } from 'Components/Modals'
// Styles
import './Styles/Modal.less'
// Ressources
import { actions as RestaurantOwnerActions } from 'Resources/RestaurantOwnerResource'

class ImageCarouselModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    menuBoards: PropTypes.array,
    openedModal: PropTypes.bool,
    onCancel: PropTypes.func
  }

  static defaultProps = {
    actions: {},
    onCancel: () => {},
    title: I18n.t('components.modals.imageCarousel.title')
  }

  constructor(props) {
    super(props)

    // États initiaux
    this.state = {
      openedModal: get(props, 'openedModal')
    }
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      openedModal: get(nextProps, 'openedModal')
    })
  }

  _handleCancel = e => {
    const { onCancel } = this.props

    this.setState(
      {
        openedModal: ''
      },
      onCancel(e)
    )
  }

  render() {
    const { title, openedModal, menuBoards } = this.props
    let oldMenuBoards = menuBoards ? [...menuBoards] : []
    remove(oldMenuBoards, currentMenuBoard => currentMenuBoard.isCurrent)

    return (
      <Container
        title={title}
        visible={openedModal}
        onCancel={this._handleCancel}
        footer={null}
        className='modal-imagesCarousel'
      >
        <Carousel ref={ref => (this.carousel = ref)} effect='fade'>
          {map(orderBy(oldMenuBoards, 'id', 'asc'), (menuBoard, index) => {
            const indexImg = parseInt(index) + 1
            return (
              <Fragment>
                <div className='carousel-image-item'>
                  <span
                    onClick={() => this.carousel.prev()}
                    onKeyDown={e => this.carousel.prev(e)}
                    role='button'
                    tabIndex='-1'
                    className='carousel-image-nav-prev'
                  >
                    <Icon
                      type='arrow-left'
                      className='icon'
                      style={{ fontSize: '2em' }}
                    />
                  </span>
                  <div className='carousel'>
                    <img
                      src={`${get(menuBoard, 'picture')}`}
                      alt=''
                      className='restaurantOwner-profile-description-item-menuBoard'
                    />
                  </div>

                  <span
                    onClick={() => this.carousel.next()}
                    onKeyUp={e => this.carousel.next(e)}
                    role='button'
                    tabIndex='0'
                    className='carousel-image-nav-next'
                  >
                    <Icon
                      type='arrow-right'
                      className='icon'
                      style={{ fontSize: '2em' }}
                    />
                  </span>
                </div>
                <h3>{indexImg}</h3>
              </Fragment>
            )
          })}
        </Carousel>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(ImageCarouselModal, 'defaultProps', {})

  return {
    menuBoards: defaultTo(
      get(state, 'restaurantOwner.menuboards'),
      defaultProps.menuBoards
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...RestaurantOwnerActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageCarouselModal)
