import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from 'Config/Theme'

const withMuiTheme = ComposedComponent => {
  return props => (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ComposedComponent {...props} />
      </ThemeProvider>
    </React.Fragment>
  )
}

export default withMuiTheme
