import React from 'react'

// Polyfills
import '@babel/polyfill'
import 'react-app-polyfill/ie11'

// Libraries
import ReactDOM from 'react-dom'
import App from 'Views/App'
import * as serviceWorker from 'Services/serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
