// Libraries
import { connect } from 'react-redux'
import { createSwitchNavigator } from '@react-navigation/core'

// Navigation Helpers
import {
  createNavigationReducer,
  reduxifyNavigator,
  createReactNavigationReduxMiddleware
} from 'Navigation/Helpers'

// Utils
import { prepareStack } from 'Navigation/Utils'

// Views
import Exception from 'Views/Exception'

// Routes
import { Authenticated, Unauthenticated } from 'Navigation/AppNavigator'

export const AppNavigator = createSwitchNavigator(
  prepareStack({
    Authenticated: {
      screen: Authenticated,
      path: ''
    },
    Unauthenticated: {
      screen: Unauthenticated,
      path: 'auth'
    },
    Exception: {
      screen: Exception,
      path: 'error'
    }
  })
)

export const navigationMiddleware = createReactNavigationReduxMiddleware(
  'root',
  state => state.nav,
  AppNavigator
)

export const navReducer = createNavigationReducer(AppNavigator)

export const ReduxAppNavigation = reduxifyNavigator(AppNavigator, 'root')

const mapStateToProps = state => ({
  state: state.nav
})

export default connect(mapStateToProps)(reduxifyNavigator(AppNavigator, 'root'))
