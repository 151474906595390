import { defaultTo, get, isNil } from 'lodash'
import { bindActionCreators } from 'redux'
import { actions as promotionRedeemActions } from 'Resources/PromotionRedeemResource'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import I18n from 'i18next'
import { TableLayout } from 'Components'
import PropTypes from 'prop-types'
import { Button, message, Modal } from 'antd'
import moment from 'moment'
import { Link } from '@react-navigation/web'

const { confirm } = Modal

class PromotionRedeemScreen extends Component {
  static propTypes = {
    // Données de la vue
    promotionRedeems: PropTypes.array,

    // Status de récupération des données
    isGathering: PropTypes.bool,

    // Actions d'API
    actions: PropTypes.object,

    // Boutons à afficher en haut à droite du tableau
    buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // Tableaux à afficher sur la page
    tables: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  }

  static defaultProps = {
    // Données de la vue
    promotionRedeems: [],

    // Status de récupération des données
    isGathering: true,

    // Boutons à afficher en haut à droite du tableau
    buttons: [],

    // Tableaux à afficher sur la page
    tables: {
      promotionRedeems: {
        rowKey: 'id',
        locale: {
          emptyText: I18n.t('pages.promotion.redeem.table.empty')
        },
        pagination: {
          position: 'both',
          showSizeChanger: true
        },
        columns: {
          id: {
            title: 'pages.promotion.redeem.table.columns.id',
            dataIndex: 'id',
            key: 'id',
            render: id => (
              <span className='promotion-code-id'>
                {!isNil(id) ? id : I18n.t('common.unknown.male')}
              </span>
            ),
            sorter: (a, b) =>
              defaultTo(get(a, 'id'), 0) - defaultTo(get(b, 'id'), 0)
          },
          code: {
            title: 'pages.promotion.redeem.table.columns.code',
            dataIndex: 'code',
            key: 'code',
            render: code => (
              <span className='promotion-code-code'>
                {!isNil(code.code) ? (
                  <Link routeName='PromotionCode' params={{ id: code.id }}>
                    {code.code}
                  </Link>
                ) : (
                  I18n.t('common.unknown.male')
                )}
              </span>
            ),
            sorter: (a, b) =>
              defaultTo(get(a, 'code.code'), '').localeCompare(
                defaultTo(get(b, 'code.code'), '')
              )
          },
          establishment: {
            title: 'pages.promotion.redeem.table.columns.establishment',
            dataIndex: 'establishment',
            key: 'establishment',
            render: establishment => (
              <span className='promotion-code-establishment'>
                {!isNil(establishment.name) ? (
                  <Link
                    routeName='RestaurantOwners'
                    params={{ id: establishment.id }}
                  >
                    {establishment.name}
                  </Link>
                ) : (
                  I18n.t('common.unknown.male')
                )}
              </span>
            ),
            sorter: (a, b) =>
              defaultTo(get(a, 'code.establishment.name'), '').localeCompare(
                defaultTo(get(b, 'code.establishment.name'), '')
              )
          },
          redeemAt: {
            title: 'pages.promotion.redeem.table.columns.redeemAt',
            dataIndex: 'redeemAt',
            key: 'redeemAt',
            render: redeemAt => (
              <span className='promotion-code-redeemAt'>
                {redeemAt !== undefined
                  ? I18n.t('date.formats.longDay', {
                      date: moment(redeemAt).toDate()
                    })
                  : '-'}
              </span>
            ),
            sorter: (a, b) =>
              moment(get(a, 'createdAt', 0)).toDate() -
              moment(get(b, 'createdAt', 0)).toDate()
          },
          expireAt: {
            title: 'pages.promotion.redeem.table.columns.expireAt',
            dataIndex: 'expireAt',
            key: 'expireAt',
            render: expireAt => (
              <span className='promotion-code-expireAt'>
                {!isNil(expireAt)
                  ? I18n.t('date.formats.longDay', {
                      date: moment(expireAt).toDate()
                    })
                  : '-'}
              </span>
            ),
            sorter: (a, b) =>
              moment(get(a, 'expireAt', 0)).toDate() -
              moment(get(b, 'expireAt', 0)).toDate()
          },
          actions: {
            title: 'pages.promotion.redeem.table.columns.actions',
            key: 'actions',
            dataIndex: 'actions',
            render: actions => (
              <span className='promotion-code-action'>
                <Button type='danger' onClick={actions._disable}>
                  {I18n.t('pages.promotion.redeem.table.actions.disable')}
                </Button>
              </span>
            )
          }
        }
      }
    }
  }

  constructor(props) {
    super(props)

    // États initiaux
    this.state = {}
  }

  componentDidMount = () => {
    // Récupération des données de la vue
    this.fetchPromotionRedeems()
  }

  /**
   * Suppression de l'abonnement
   */
  deletePromotionRedeem = promotionCode => {
    this.props.actions
      .deletePromotionRedeem({ id: promotionCode.id })
      .then(() => {
        message.success(
          I18n.t(`api.success.restaurantOwners.deleteSubscription`)
        )
      })
      .catch(() => {
        message.error(I18n.t(`api.errors.restaurantOwners.deleteSubscription`))
      })
  }

  _disable = promotionRedeem => {
    confirm({
      title: I18n.t(
        'pages.promotion.redeem.table.components.modal.disable.title'
      ),
      content: I18n.t(
        'pages.promotion.redeem.table.components.modal.disable.content'
      ),
      okText: I18n.t('common.disable'),
      okType: 'danger',
      cancelText: I18n.t('common.cancel'),
      onOk: () => {
        this.deletePromotionRedeem(promotionRedeem)
      },
      maskClosable: true
    })
  }

  /**
   * Récupère les clients
   */
  fetchPromotionRedeems = () => {
    this.props.actions
      .fetchPromotionRedeems()
      .catch(() => message.errors('api.errors.user.fetch'))
  }

  render() {
    const { tables, buttons, isGathering, promotionRedeems } = this.props

    // Ajout des données aux tableaux
    promotionRedeems.forEach(promotionRedeem => {
      promotionRedeem.actions = {
        _disable: () => this._disable(promotionRedeem)
      }
    })
    tables.promotionRedeems.rows = promotionRedeems

    // Ajout des actions au clic sur un ligne du tableau
    // tables.customers.onRow = (customer, id) => ({
    //   onClick: () => this.onClickCustomer(customer, id)
    // })

    return (
      <main className='promotion-code screen'>
        {/* Tableau d'utilisateurs */}
        <TableLayout
          elementName='promotionRedeem'
          elementKind='male'
          buttons={[...buttons]}
          elementConsonant
          loading={isGathering}
          tables={tables}
        />
      </main>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(PromotionRedeemScreen, 'defaultProps', {})
  return {
    promotionRedeems: defaultTo(
      get(state, 'promotionRedeem.items'),
      defaultProps.promotionCode
    ),
    isGathering: defaultTo(
      get(state, 'promotionRedeem.isFetching'),
      defaultProps.isGathering
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...promotionRedeemActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionRedeemScreen)
