// @flow

// Libraries
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

// Sagas
import rootSaga from 'Sagas/'

// Store
import configureStore from 'Redux/CreateStore'

// Reducer
import { reducer as startupReducer } from 'Redux/StartupRedux'

// Resources
import { navReducer } from 'Navigation/NavigationRouter'
import { rootReducer as apiReducer } from 'Resources/ApiResource'
import { rootReducer as langReducer } from 'Resources/LangResource'
import { rootReducer as authReducer } from 'Resources/AuthResource'
import { rootReducer as profileReducer } from 'Resources/ProfileResource'
import { rootReducer as fileReducer } from 'Resources/FileResource'
import { rootReducer as restaurantOwnerReducer } from 'Resources/RestaurantOwnerResource'
import { rootReducer as statisticsReducer } from 'Resources/StatisticsResource'
import { rootReducer as eventsReducer } from 'Resources/EventsResource'
import { rootReducer as bookingReducer } from 'Resources/BookingResource'
import { rootReducer as orderReducer } from 'Resources/OrderResource'
import { rootReducer as customerReducer } from 'Resources/CustomerResource'
import { rootReducer as promotionCodeReducer } from 'Resources/PromotionCodeResource'
import { rootReducer as promotionRedeemReducer } from 'Resources/PromotionRedeemResource'
import { reducer as webSocketReducer } from 'WebSocket/Redux'

// Config
import { ReduxPersist } from 'Config'

export default () => {
  /* ------------- Assemble The Reducers ------------- */
  let rootReducer = combineReducers({
    startup: startupReducer,
    lang: langReducer,
    nav: navReducer,
    api: apiReducer,
    auth: authReducer,
    profile: profileReducer,
    file: fileReducer,
    websocket: webSocketReducer,
    restaurantOwner: restaurantOwnerReducer,
    statistics: statisticsReducer,
    events: eventsReducer,
    booking: bookingReducer,
    order: orderReducer,
    customer: customerReducer,
    promotionCode: promotionCodeReducer,
    promotionRedeem: promotionRedeemReducer
  })

  /* ------------- Config persistant store ------------- */
  if (ReduxPersist.active) {
    rootReducer = persistReducer(ReduxPersist.storeConfig, rootReducer)
  }

  return configureStore(rootReducer, rootSaga)
}
