import { put, select, takeLatest } from 'redux-saga/effects'
import {
  types as ConversationTypes,
  actions as conversationActions
} from 'Resources/ConversationResource'
import { actions as restaurantOwnerActions } from 'Resources/RestaurantOwnerResource'

export function* conversationSagas() {
  yield takeLatest(
    action =>
      action.type === ConversationTypes.ENABLE_CONVERSATION &&
      action.status === 'resolved',
    enableConversation
  )
  yield takeLatest(
    action =>
      action.type === ConversationTypes.DISABLE_CONVERSATION &&
      action.status === 'resolved',
    disableConversation
  )
}

function* enableConversation(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions.push('api.conversation_access')
  yield put(conversationActions.setRestaurantOwnerConversation(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}

function* disableConversation(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions = restaurantOwner.permissions.filter(
    permission => permission !== 'api.conversation_access'
  )
  yield put(conversationActions.setRestaurantOwnerConversation(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}
