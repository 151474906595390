import { put, select, takeLatest } from 'redux-saga/effects'
import {
  types as MultiPhotoTypes,
  actions as multiPhotoActions
} from 'Resources/MultiPhotoResource'
import { actions as restaurantOwnerActions } from 'Resources/RestaurantOwnerResource'

export function* multiPhotoSagas() {
  yield takeLatest(
    action =>
      action.type === MultiPhotoTypes.ENABLE_MULTI_PHOTO &&
      action.status === 'resolved',
    enableMultiPhoto
  )
  yield takeLatest(
    action =>
      action.type === MultiPhotoTypes.DISABLE_MULTI_PHOTO &&
      action.status === 'resolved',
    disableMultiPhoto
  )
}

function* enableMultiPhoto(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions.push('api.multi_photo_access')
  yield put(multiPhotoActions.setRestaurantOwnerMultiPhoto(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}

function* disableMultiPhoto(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions = restaurantOwner.permissions.filter(
    permission => permission !== 'api.multi_photo_access'
  )
  yield put(multiPhotoActions.setRestaurantOwnerMultiPhoto(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}
