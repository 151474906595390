import { put, select, takeLatest } from 'redux-saga/effects'
import {
  types as BookingTypes,
  actions as bookingActions
} from 'Resources/BookingResource'
import { actions as restaurantOwnerActions } from 'Resources/RestaurantOwnerResource'

export function* bookingSagas() {
  yield takeLatest(
    action =>
      action.type === BookingTypes.ENABLE_BOOKING &&
      action.status === 'resolved',
    enableBooking
  )
  yield takeLatest(
    action =>
      action.type === BookingTypes.DISABLE_BOOKING &&
      action.status === 'resolved',
    disableBooking
  )
}

function* enableBooking(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions.push('api.booking_access')
  yield put(bookingActions.setRestaurantOwnerBooking(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}

function* disableBooking(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions = restaurantOwner.permissions.filter(
    permission => permission !== 'api.booking_access'
  )
  yield put(bookingActions.setRestaurantOwnerBooking(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}
