import React, { Component } from 'react'

// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { get, defaultTo } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { message, Tabs, Upload, Modal, Avatar, Alert, Button } from 'antd'

// Ressources
import { actions as profileActions } from 'Resources/ProfileResource'
import { actions as fileActions } from 'Resources/FileResource'

// Tabs
import { Informations } from './Tabs'

// Modales
import { ChangePassword } from 'Components/Modals'

// Styles
import './Styles/ProfileScreen.less'

const { TabPane } = Tabs
const { confirm } = Modal

class ProfileScreen extends Component {
  static propTypes = {
    actions: PropTypes.object,
    profile: PropTypes.object,
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    profile: {}
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: '',
      previewImage: '',
      fileList: []
    }
  }

  componentDidMount = () => {
    // Récupération des données du profile
    this._getProfileData()

    // Lecteur de fichier
    this.fileReader = new FileReader()
  }

  /**
   * Récupération des données du profile
   */
  _getProfileData = () => {
    this.props.actions.getProfile().catch(() => {
      message.error(I18n.t('api.errors.profile.get'))
    })
  }

  /**
   * Fermeture de toutes les modales
   */
  _handleCloseModal = () => {
    this.setState({ openedModal: '' })
  }

  /**
   * Ouverture de la modale de modification du mot de passe
   */
  _askChangePassword = () => {
    this.setState({ openedModal: 'changePassword' })
  }

  /**
   * Demande de suppression d'un profile
   */
  _askDeleteProfile = profile => {
    confirm({
      title: I18n.t('components.modals.deleteProfile.title'),
      content: I18n.t('components.modals.deleteProfile.content'),
      okText: I18n.t('components.modals.deleteProfile.okText'),
      okType: 'danger',
      cancelText: I18n.t('components.modals.deleteProfile.cancelText'),
      onOk: () => {
        this._deleteProfile(profile)
      },
      maskClosable: true
    })
  }

  /**
   * Met à jour l'image stockée
   */
  _handleChangeUpload = info => {
    this.fileReader.onloadend = obj => {
      this.setState({
        image: obj.srcElement.result
      })
    }
    this.fileReader.readAsDataURL(info.file.originFileObj)
  }

  _customRequest = option => {
    const { file: avatar } = option

    this.props.actions
      .updateAvatarProfile(
        { avatar },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      .then(() => {
        message.success(I18n.t('api.success.profile.uploadAvatar'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.profile.uploadAvatar'))
      })
      .finally(() => {
        this.setState({ uploading: false })
      })
  }

  _onSuccess = logo => {
    const { profile } = this.props

    this.props.actions
      .updateProfile({ ...profile, logo })
      .then(() => {
        message.success(I18n.t('api.success.profile.uploadAvatar'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.profile.uploadAvatar'))
      })
      .finally(() => {
        this.setState({ uploading: false })
      })
  }

  _onError = () => {
    this.setState({ uploading: false })
    message.error(I18n.t('api.errors.profile.uploadAvatar'))
  }

  /**
   * On affiche le bouton d'upload || l'image
   */
  _renderUpload = () => {
    const { uploading } = this.state
    const { profile } = this.props

    return (
      <Upload
        name='avatar'
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        accept='image/png, image/jpeg'
        action={`${process.env.REACT_APP_API_SERVER_URL}/files`}
        customRequest={this._customRequest}
        onChange={this._handleChangeUpload}
        onSuccess={this._onSuccess}
        multiple={false}
        withCredentials
      >
        <Avatar
          src={get(profile, 'avatar')}
          className='avatar'
          icon={uploading ? 'loading' : 'user'}
        />
      </Upload>
    )
  }

  render() {
    const { profile, navigation } = this.props
    const { openedModal } = this.state

    return (
      <main className='screen profile'>
        {/* Résumé d'informations sur le profile */}
        <header>
          <div className='resume'>
            {/* Logo */}
            {this._renderUpload()}

            {/* Information sur le profile */}
            <div className='informations'>
              {/* Nom complet */}
              <h2 className='profile-fullName'>
                <span className='profile-firstName'>
                  {get(profile, 'firstname', '')}
                </span>
                <span className='profile-lastName'>
                  {get(profile, 'lastname', '')}
                </span>
              </h2>

              <Alert
                message={I18n.t('pages.profile.upload.notice')}
                type='info'
                showIcon
              />
            </div>
          </div>

          <div className='buttons'>
            <Button onClick={this._askChangePassword} type='primary'>
              {I18n.t('pages.profile.changePassword')}
            </Button>
          </div>
        </header>

        {/* Tabs */}
        <Tabs defaultActiveKey='informations' type='card'>
          {/* Informations */}
          <TabPane
            tab={I18n.t('pages.profile.tabs.informations.title')}
            key='informations'
          >
            <Informations navigation={navigation} />
          </TabPane>
        </Tabs>

        {/* Modale de changement de mot de passe */}
        <ChangePassword
          visible={openedModal === 'changePassword'}
          onOk={this._handleCloseModal}
          onCancel={this._handleCloseModal}
        />
      </main>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(ProfileScreen, 'defaultProps', {})

  return {
    profile: defaultTo(get(state, 'profile.item'), defaultProps.profile)
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...profileActions, ...fileActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileScreen)
