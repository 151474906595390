import { put, select, takeLatest } from 'redux-saga/effects'
import {
  types as GamingTypes,
  actions as gamingActions
} from 'Resources/GamingResource'
import { actions as restaurantOwnerActions } from 'Resources/RestaurantOwnerResource'

export function* gamingSagas() {
  yield takeLatest(
    action =>
      action.type === GamingTypes.ENABLE_GAMING && action.status === 'resolved',
    enableGaming
  )
  yield takeLatest(
    action =>
      action.type === GamingTypes.DISABLE_GAMING &&
      action.status === 'resolved',
    disableGaming
  )
}

function* enableGaming(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions.push('api.gaming_access')
  yield put(gamingActions.setRestaurantOwnerGaming(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}

function* disableGaming(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions = restaurantOwner.permissions.filter(
    permission => permission !== 'api.gaming_access'
  )
  yield put(gamingActions.setRestaurantOwnerGaming(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}
