import React, { useCallback, useEffect, useState } from 'react'
import withMuiTheme from 'Hoc/WithMuiTheme'
import { Divider } from '@material-ui/core'
import './Styles/StatisticsScreen.less'
import RestaurantOwnerSelector from 'Views/Statistics/Components/RestaurantOwnerSelector'
import { defaultTo, get } from 'lodash'
import { bindActionCreators } from 'redux'
import { actions as restaurantOwnerActions } from 'Resources/RestaurantOwnerResource'
import { actions as statisticsActions } from 'Resources/StatisticsResource'
import { connect } from 'react-redux'
import { message } from 'antd'
import StatisticsView from 'Views/Statistics/Components/StatisticsView'
import moment from 'moment'
import PropTypes from 'prop-types'
import I18n from 'i18next'

const StatisticsScreen = ({
  restaurantOwners,
  restaurantOwner,
  actions,
  statisticsStartDate,
  statisticsEndDate,
  statistics
}) => {
  useEffect(() => {
    if (restaurantOwners.length === 0) {
      actions
        .fetchRestaurantOwners()
        .catch(() => message.error('api.errors.user.fetch'))
    }
  }, [])

  const [inProgress, setInProgress] = useState(false)
  const [currentRestaurantOwner, setCurrentRestaurantOwner] = useState(
    restaurantOwner
  )
  const [startDate, setStartDate] = useState(statisticsStartDate)
  const [endDate, setEndDate] = useState(statisticsEndDate)

  const updateStatistics = useCallback(() => {
    if (!inProgress && restaurantOwner !== undefined) {
      setInProgress(true)
      actions
        .fetchStatistics({
          id: get(restaurantOwner, 'establishments[0].id'),
          startDate: moment(statisticsStartDate).format('YYYY-MM-DD'),
          endDate: moment(statisticsEndDate).format('YYYY-MM-DD')
        })
        .catch(() => {
          message.error(I18n.t('api.errors.user.fetch'))
        })
        .finally(() => {
          setStartDate(statisticsStartDate)
          setEndDate(statisticsEndDate)
          setCurrentRestaurantOwner(restaurantOwner)
          setInProgress(false)
        })
    }
  }, [restaurantOwner, inProgress, statisticsEndDate, statisticsStartDate])

  useEffect(() => {
    if (
      statistics === undefined ||
      !moment(startDate).isSame(moment(statisticsStartDate), 'day') ||
      !moment(endDate).isSame(moment(statisticsEndDate), 'day') ||
      get(currentRestaurantOwner, 'id') !== get(restaurantOwner, 'id')
    ) {
      updateStatistics()
    }
  }, [
    restaurantOwner,
    currentRestaurantOwner,
    statistics,
    statisticsStartDate,
    statisticsEndDate,
    startDate,
    endDate,
    updateStatistics
  ])

  const handleUpdateStartDate = date => {
    actions.setStartDateStatistics(date)
  }

  const handleUpdateEndDate = date => {
    actions.setEndDateStatistics(date)
  }

  const handleEnableRestaurantOwner = () => {
    actions
      .enableStatistics({
        id: get(restaurantOwner, 'establishments[0].id'),
        restaurantOwnerId: restaurantOwner.id
      })
      .then(() => {
        message.success(I18n.t('pages.statistics.api.enable.success'))
      })
      .catch(() => {
        message.error(I18n.t('pages.statistics.api.enable.error'))
      })
  }

  const handleDisableRestaurantOwner = () => {
    actions
      .disableStatistics({
        id: get(restaurantOwner, 'establishments[0].id'),
        restaurantOwnerId: restaurantOwner.id
      })
      .then(() => {
        message.success(I18n.t('pages.statistics.api.disable.success'))
      })
      .catch(() => {
        message.error(I18n.t('pages.statistics.api.disable.error'))
      })
  }

  return (
    <main className='statistics'>
      <Divider />
      <RestaurantOwnerSelector
        restaurantOwner={restaurantOwner}
        restaurantOwners={restaurantOwners}
        onSelectRestaurantOwner={restaurantOwner =>
          actions.setRestaurantOwnerStatistics(restaurantOwner)
        }
        onEnableRestaurantOwner={handleEnableRestaurantOwner}
        onDisableRestaurantOwner={handleDisableRestaurantOwner}
      />
      <Divider />
      <StatisticsView
        statistics={statistics}
        startDate={statisticsStartDate}
        endDate={statisticsEndDate}
        updateStartDate={handleUpdateStartDate}
        updateEndDate={handleUpdateEndDate}
      />
    </main>
  )
}

StatisticsScreen.propTypes = {
  statistics: PropTypes.array,
  restaurantOwners: PropTypes.array,
  restaurantOwner: PropTypes.object,
  statisticsStartDate: PropTypes.object,
  statisticsEndDate: PropTypes.object,
  actions: PropTypes.object
}

const mapStateToProps = state => {
  console.log('mapStateToProps', get(state, 'restaurantOwner.items'))
  return {
    restaurantOwners: defaultTo(get(state, 'restaurantOwner.items'), []),
    isGathering: defaultTo(get(state, 'restaurantOwner.isFetching'), false),
    statistics: get(state, 'statistics.items'),
    restaurantOwner: defaultTo(
      get(state, 'statistics.restaurantOwner'),
      undefined
    ),
    statisticsStartDate: defaultTo(
      get(state, 'statistics.startDate'),
      moment()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(-2, 'weeks')
        .toDate()
    ),
    statisticsEndDate: defaultTo(
      get(state, 'statistics.endDate'),
      moment()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toDate()
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { ...restaurantOwnerActions, ...statisticsActions },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMuiTheme(StatisticsScreen))
