import React from 'react'
import useStyles from './Styles/FlatCard.css'
import { Card } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const FlatCard = React.forwardRef(
  ({ className, elevation = 0, ...props }, ref) => {
    const classes = useStyles()
    return (
      <Card
        ref={ref}
        elevation={elevation}
        className={clsx(classes.card, className)}
        {...props}
      />
    )
  }
)

FlatCard.propTypes = {
  className: PropTypes.string,
  elevation: PropTypes.number
}

export default FlatCard
