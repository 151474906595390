// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { Form, message } from 'antd'
import sha512 from 'crypto-js/sha512'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { get, defaultTo, kebabCase, has, isEmpty } from 'lodash'

// Components
import {
  LoginForm,
  ForgottenForm,
  CreatePasswordForm
} from 'Views/Authentication/Components'

// Resources
import { actions as authActions } from 'Resources/AuthResource'

// Helpers
import { createFormFields } from 'Helpers'

// Styles
import './Styles/LoginScreen.less'

// Gestion du mode de démonstration
const isMockup = process.env.REACT_APP_MOCK_MODE === 'true'

// Sauvegarde du formulaire en temps réel
// Permet de conserver les valeurs si le composant est re-render
let UNSAVED_FORM = {}

class LoginScreen extends Component {
  static propTypes = {
    form: PropTypes.object,
    actions: PropTypes.object,
    isLoading: PropTypes.object,
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    isLoading: {
      login: false,
      forgotten: false,
      create: false
    }
  }

  constructor(props) {
    super(props)

    // initial state
    this.state = {
      visible: 'login'
    }
  }

  /**
   * Passage d'un formulaire à l'autre
   * @param  form
   * @private
   */
  _switchForm = (form, params = {}) => {
    this.setState({
      visible: form,
      params
    })
  }

  /**
   * Quand le formulaire de login est submit
   * @param event
   * @private
   */
  _handleSubmitLogin = event => {
    event.preventDefault()

    if (!isMockup) {
      this.props.form.validateFields(
        (error, { login, password, newPassword }) => {
          // Si aucune erreur n'est détecté dans le form
          if (!error) {
            // Permet de gérer la connexion depuis la création de mot de passe
            password = password || newPassword

            this.props.actions
              .loginAuth({
                email: login,
                password: sha512(password).toString()
              })
              .then(({ body }) => {
                this.props.actions.setAuth(body)
              })
              .catch(error => {
                if (!has(error, 'statusCode')) {
                  message.error(I18n.t('api.errors.default'))
                } else {
                  const key = get(error, 'body.key', '')

                  message.error(I18n.t(`api.errors.login.${kebabCase(key)}`))
                }
              })
          }
        }
      )
    } else {
      this.props.navigation.navigate('Authenticated')
    }
  }

  /**
   * Quand le formulaire de création de mot de passe est submit
   * @param event
   * @private
   */
  _handleCreatePassword = event => {
    event.preventDefault()

    if (!isMockup) {
      this.props.form.validateFields((error, { login, newPassword }) => {
        const code = this.props.form.getFieldValue('temporaryCode')

        if (!error) {
          this.props.actions
            .setPasswordAuth({
              login: login,
              newPassword: sha512(newPassword).toString(),
              code
            })
            .then(() => {
              this._handleSubmitLogin(event)
            })
            .catch(() => {
              message.error(I18n.t('api.errors.login.create'))
            })
        }
      })
    } else {
      this._handleSubmitLogin(event)
    }
  }

  render() {
    const { form, isLoading } = this.props
    const { params, visible } = this.state

    return (
      <div className='login screen'>
        {/* Formulaire de login */}
        <LoginForm
          form={form}
          fetching={isLoading.login || isLoading.firstLoggingIn}
          onSubmit={this._handleSubmitLogin}
          hidden={visible !== 'login'}
          switchForm={this._switchForm}
          {...params}
        />

        {/* Formulaire de mot de passe oublié */}
        <ForgottenForm
          form={form}
          fetching={isLoading.forgotten}
          hidden={visible !== 'forgotten'}
          switchForm={this._switchForm}
          {...params}
        />

        {/* Formulaire de changement de mot de passe (première connexion) */}
        <CreatePasswordForm
          form={form}
          fetching={isLoading.create}
          onSubmit={this._handleCreatePassword}
          hidden={visible !== 'create'}
          switchForm={this._switchForm}
          {...params}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(LoginScreen, 'defaultProps', {})

  return {
    isLoading: {
      login: defaultTo(
        get(state, 'auth.isLoggingIn'),
        defaultProps.isLoading.login
      ),
      firstLoggingIn: defaultTo(
        get(state, 'auth.isFirstLoggingIn'),
        defaultProps.isLoading.login
      ),
      forgotten: defaultTo(
        get(state, 'auth.isAskingForgotten'),
        defaultProps.isLoading.forgotten
      ),
      create: defaultTo(
        get(state, 'auth.isCreatingPassword'),
        defaultProps.isLoading.create
      )
    }
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...authActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    onValuesChange(props, fieldValue, fieldsValue) {
      UNSAVED_FORM = fieldsValue
    },
    mapPropsToFields(props) {
      // Compte et rôles
      const roles = {
        supervisor: {
          login: 'test@ubidreams.com',
          password: 'Abcd1234!'
        },
        superadmin: {
          login: 'superadmin-test@ubidreams.com',
          password: 'Abcd1234!'
        }
      }

      // Valeurs par défaut du mode development
      const currentRole = roles['superadmin']

      // Conservation du formulaire
      const formData = !isEmpty(UNSAVED_FORM)
        ? UNSAVED_FORM
        : process.env.NODE_ENV === 'development'
        ? currentRole
        : {}

      return createFormFields(Form, formData)
    }
  })(LoginScreen)
)
