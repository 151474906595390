import Immutable from 'seamless-immutable'
import { cloneDeep, get, without } from 'lodash'
import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  wsConnect: ['name', 'address', 'protocols', 'options'],
  wsConnecting: ['name', 'address', 'protocols', 'options'],
  wsConnected: ['name', 'address', 'protocols', 'options'],

  wsDisconnect: ['name'],
  wsDisconnecting: ['name'],
  wsDisconnected: ['name', 'event'],

  wsMessageReceived: ['name', 'content'],
  wsReset: null,

  wsSend: ['name', 'content'],
  wsMessageSent: ['name', 'content'],

  wsError: ['name', 'error'],
  wsErrorHandled: ['name', 'code'],
  wsErrorDismissed: ['name', 'code']
})

export const WebSocketTypes = Types

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({})

/* ------------- Reducers ------------- */

// Requête en cours
export const request = (
  state,
  { name = 'default', address, protocols, options }
) => {
  const nextState = cloneDeep(state)
  nextState[name] = {
    ...nextState[name],
    address,
    protocols,
    options,
    connecting: true
  }

  return nextState
}

// Connexion effectuée
export const connected = (
  state,
  { name = 'default', address, protocols, options }
) => {
  const nextState = cloneDeep(state)
  nextState[name] = {
    ...nextState[name],
    address,
    protocols,
    options,
    handledErrors: [],
    connecting: false,
    connected: true
  }

  return nextState
}

// Déconnexion effectuée
export const disconnected = (state, { name = 'default', event }) => {
  const nextState = cloneDeep(state)
  nextState[name] = {
    ...nextState[name],
    connecting: false,
    connected: false,
    error: {
      ...get(nextState[name], 'error'),
      code: get(event, 'code'),
      reason: get(event, 'reason')
    }
  }

  return nextState
}

// Erreur de websocket
export const error = (state, { name = 'default', error }) => {
  const nextState = cloneDeep(state)

  nextState[name] = {
    ...nextState[name],
    error: {
      ...get(nextState[name], 'error', {}),
      ...error
    }
  }

  return nextState
}

// Erreur de websocket affichée
export const errorHandled = (state, { name = 'default', code }) => {
  const nextState = cloneDeep(state)

  nextState[name] = {
    ...nextState[name],
    handledErrors: [...get(nextState[name], 'handledErrors', []), code]
  }

  return nextState
}

// Erreur de websocket effacée
export const errorDismissed = (state, { name = 'default', code }) => {
  const nextState = cloneDeep(state)

  nextState[name] = {
    ...nextState[name],
    handledErrors: without(get(nextState[name], 'handledErrors', []), code)
  }

  return nextState
}

// Message reçu
export const messageReceived = (state, { name = 'default', content }) => {
  const nextState = cloneDeep(state)
  nextState[name] = { ...nextState[name], lastReceivedMessage: content }

  return nextState
}

// Message envoyé
export const messageSent = (state, { name = 'default', content }) => {
  const nextState = cloneDeep(state)
  nextState[name] = { ...nextState[name], lastSentMessage: content }

  return nextState
}

// Réinitialisation de la state
export const wsReset = (state, action) => {
  return INITIAL_STATE
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.WS_CONNECT]: request,
  [Types.WS_CONNECTING]: request,
  [Types.WS_CONNECTED]: connected,

  [Types.WS_DISCONNECT]: request,
  [Types.WS_DISCONNECTING]: request,
  [Types.WS_DISCONNECTED]: disconnected,

  [Types.WS_MESSAGE_RECEIVED]: messageReceived,

  [Types.WS_SEND]: request,
  [Types.WS_MESSAGE_SENT]: messageSent,

  [Types.WS_RESET]: wsReset,

  [Types.WS_ERROR]: error,
  [Types.WS_ERROR_HANDLED]: errorHandled,
  [Types.WS_ERROR_DISMISSED]: errorDismissed
})
