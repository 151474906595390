import { createResource } from 'redux-rest-resource'
import { baseURL } from './'

export const { types, actions, rootReducer } = createResource({
  name: 'file',
  url: `${baseURL}`,
  credentials: 'include',
  actions: {
    getToken: {
      method: 'GET',
      gerundName: 'gettingToken',
      url: './files/token'
    },
    getData: {
      method: 'GET',
      gerundName: 'gettingData',
      url: './files/:id/data'
    }
  }
})
