// Libraries
import I18n from 'i18next'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import { get, defaultTo, find, map, reduce } from 'lodash'

// Styles
import './Styles/LanguageSwitcherComponent.less'

const { confirm } = Modal

const langCodes = {
  cs: { lang: 'cs', code: 'CZ', name: 'čeština' },
  de: { lang: 'de', code: 'DE', name: 'Deutsche' },
  en: { lang: 'en', code: 'GB', name: 'English' },
  es: { lang: 'es', code: 'ES', name: 'Español' },
  fr: { lang: 'fr', code: 'FR', name: 'Français' },
  it: { lang: 'it', code: 'IT', name: 'Italiano' },
  ja: { lang: 'ja', code: 'JP', name: '日本人' },
  ko: { lang: 'ko', code: 'KR', name: '한국어' },
  pl: { lang: 'pl', code: 'PL', name: 'Polskie' },
  ru: { lang: 'ru', code: 'RU', name: 'русский' },
  uk: { lang: 'uk', code: 'UA', name: 'Українська' },
  zh: { lang: 'zh', code: 'CN', name: '中文' }
}

class LanguageSwitcher extends Component {
  static propTypes = {
    countries: PropTypes.array,
    lang: PropTypes.object
  }

  static defaultProps = {
    countries: ['fr', 'en']
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  handleSelect = code => {
    I18n.changeLanguage(find(langCodes, { code }).lang, () => {
      setTimeout(() => {
        confirm({
          title: I18n.t('components.languageSwitcher.notice.title'),
          content: I18n.t('components.languageSwitcher.notice.description'),
          okText: I18n.t('common.yes'),
          cancelText: I18n.t('common.no'),
          onOk() {
            window.location.reload()
          }
        })
      }, 0)
    })
  }

  render() {
    const {
      countries,
      lang: { language, fallbackLng = 'fr', whitelist = countries }
    } = this.props

    const customLabels = reduce(langCodes, (result, { code, name }) => {
      result[code] = name
      return result
    })

    return (
      <ReactFlagsSelect
        countries={map(whitelist, lang => langCodes[lang].code)}
        onSelect={this.handleSelect}
        defaultCountry={langCodes[defaultTo(language, fallbackLng)].code}
        customLabels={customLabels}
        showSelectedLabel={false}
      />
    )
  }
}

const mapStateToProps = state => ({
  lang: get(state, 'lang')
})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSwitcher)
