import React, { Component } from 'react'

// Libraries
import { get } from 'lodash'
import { Layout } from 'antd'
import PropTypes from 'prop-types'
import { SceneView } from '@react-navigation/core'

// Components
// import { Footer, Breadcrumbs } from 'Navigation/Layout'
import { Footer, Header, Sider } from 'Navigation/Layout'

const { Content } = Layout

class AuthenticatedLayout extends Component {
  static propTypes = {
    descriptors: PropTypes.object,
    navigation: PropTypes.object
  }

  static defaultProps = {
    descriptors: {}
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { descriptors, navigation } = this.props
    const currentScreen = get(
      navigation,
      `state.routes[${get(navigation, 'state.index')}]`
    )
    const descriptor = get(descriptors, currentScreen.key)

    return (
      <Layout>
        {/* Menu lateral */}
        <Sider
          currentScreen={currentScreen}
          descriptor={descriptor}
          collapsible
        />
        <Layout>
          {/* En-tête de page */}
          <Header descriptor={descriptor} />

          <Content>
            {/* Fil d'ariane */}
            {/* <Breadcrumbs /> */}

            {/* Page avec écran actif */}
            <SceneView
              component={descriptor.getComponent()}
              navigation={descriptor.navigation}
            />

            {/* Pied de page */}
            <Footer />
          </Content>
        </Layout>
      </Layout>
    )
  }
}

export default AuthenticatedLayout
