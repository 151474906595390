// External libs
import {
  createTheme,
  darken,
  lighten,
  responsiveFontSizes
} from '@material-ui/core/styles'

let theme = createTheme({
  palette: {
    primary: {
      main: '#F38355',
      contrastText: '#fff'
    },
    secondary: {
      main: '#8b8b8b'
    },
    background: {
      default: '#ffffff'
    },
    text: {
      primary: '#6c6c6c',
      secondary: '#000'
    },
    border: {
      main: '#EBE6EF',
      contrastText: '#F0F0F0'
    }
  },
  socialColors: {
    facebook: '#1877F2',
    facebookStats: darken('#1877F2', 0.3),
    facebookStatsLight: '#1877F2',
    instagram: '#E4405F',
    instagramStats: '#FB6B53',
    instagramStatsLight: lighten('#FB6B53', 0.3),
    googleMyBusiness: '#000',
    googleMyBusinessLight: lighten('#000', 0.3),
    twitter: '#1DA1F2',
    twitterStats: lighten('#1DA1F2', 0.3),
    twitterStatsLight: lighten('#1DA1F2', 0.6)
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    h1: {
      fontSize: '1.5rem',
      lineHeight: 1.5,
      fontWeight: 600
    },
    h2: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontStyle: 'italic'
    },
    h3: {
      fontSize: '1.4rem',
      lineHeight: 1.5,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    h4: {
      fontSize: '1.2rem',
      lineHeight: 1.5,
      fontWeight: 600,
      marginBottom: 8
    },
    h5: {
      fontSize: '1.1rem',
      fontWeight: 600
    },
    h6: {
      fontSize: '1.1rem',
      lineHeight: 1.5
    },
    body2: {
      fontSize: '0.8rem'
    }
  },
  fontsWeight: {
    bold: 700,
    semiBold: 600,
    light: 300
  },
  overrides: {
    MuiRadio: {
      root: {
        color: '#6c6c6c'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 600,
        borderRadius: 6,
        minHeight: 42
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: '#CECECE',
        color: '#fff'
      },
      label: {
        textAlign: 'center'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#605B63',
        fontWeight: 600,
        '&.Mui-focused': {
          color: '#000000'
        },
        '&:not(.Mui-required)': {
          color: '#6c6c6c'
        }
      },
      asterisk: {
        color: '#F38355',
        fontWeight: 600
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#605B63'
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#6c6c6c',
        '&.Mui-checked': {
          color: '#F38355'
        }
      }
    },
    MuiTab: {
      root: {
        fontSize: '1.2rem',
        textTransform: 'none'
      }
    },
    MuiSnackbarContent: {
      message: {
        fontSize: '1.2rem'
      }
    },
    MuiTypography: {
      root: {
        whiteSpace: 'pre-line'
      },
      body1: {
        textAlign: 'left'
      },
      body2: {
        textAlign: 'left'
      }
    },
    MuiFormHelperText: {
      root: {
        whiteSpace: 'pre-line'
      }
    }
  }
})
theme = responsiveFontSizes(theme)
// theme.typography.body1 = {
//     ...theme.typography.body1,
//     fontSize: '1rem',
//     [theme.breakpoints.down('xs')]: {
//         fontSize: '0.8rem',
//     },
// }
// theme.typography.h6 = {
//     ...theme.typography.h6,
//     fontSize: '1.05rem',
//     [theme.breakpoints.down('xs')]: {
//         fontSize: '0.9rem',
//     },
// }
// const overrides = theme.overrides
// if (overrides) {
//     overrides.MuiButton = {
//         ...overrides.MuiButton,
//         root: {
//             ...overrides.MuiButton?.root,
//             [theme.breakpoints.down('xs')]: {
//                 fontSize: '0.8rem',
//             },
//         },
//     }
// }
export default theme
