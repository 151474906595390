// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { defaultTo, find, get, isEmpty, isNil, unset } from 'lodash'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal, Tag } from 'antd'
// Styles
import './Styles/RestaurantOwnersScreen.less'
// Components
import { TableLayout } from 'Components'
import { Drawer } from './Components'
// Ressources
import { actions as restaurantOwnerActions } from 'Resources/RestaurantOwnerResource'
import { baseURL } from 'Resources'
import moment from 'moment'

const { confirm } = Modal

class RestaurantOwnersScreen extends Component {
  static propTypes = {
    // Données de la vue
    restaurantOwners: PropTypes.array,

    // Status de récupération des données
    isGathering: PropTypes.bool,

    // Actions d'API
    actions: PropTypes.object,

    // Boutons à afficher en haut à droite du tableau
    buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // Tableaux à afficher sur la page
    tables: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    navigation: PropTypes.any
  }

  static defaultProps = {
    // Données de la vue
    restaurantOwners: [],

    // Status de récupération des données
    isGathering: true,

    // Boutons à afficher en haut à droite du tableau
    buttons: [],

    // Tableaux à afficher sur la page
    tables: {
      restaurantOwners: {
        multiDelete: {},
        rowKey: 'id',
        locale: {
          emptyText: I18n.t('pages.restaurantOwners.table.empty')
        },
        pagination: {
          position: 'both',
          showSizeChanger: true
        },
        columns: {
          filteredProperties: ['account', 'establishments'],
          id: {
            title: 'pages.restaurantOwners.table.columns.id',
            dataIndex: 'id',
            key: 'id',
            render: id => (
              <span className='user-id'>
                {!isNil(id) ? id : I18n.t('common.unknown.male')}
              </span>
            ),
            sorter: (a, b) =>
              defaultTo(get(a, 'id'), 0) - defaultTo(get(b, 'id'), 0)
          },
          fullname: {
            title: 'pages.restaurantOwners.table.columns.fullName',
            dataIndex: 'fullname',
            key: 'fullname',
            render: fullName => (
              <span className='user-fullName'>
                {!isNil(fullName) ? fullName : I18n.t('common.unknown.male')}
              </span>
            ),
            sorter: (a, b) =>
              defaultTo(get(a, 'fullname'), '').localeCompare(
                defaultTo(get(b, 'fullname'), '')
              )
          },
          email: {
            title: 'pages.restaurantOwners.table.columns.email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) =>
              defaultTo(get(a, 'email'), '').localeCompare(
                defaultTo(get(b, 'email'), '')
              )
          },
          establishmentId: {
            title: 'pages.restaurantOwners.table.columns.establishmentId',
            dataIndex: 'establishments',
            key: 'establishmentId',
            render: establishments => {
              return !isEmpty(establishments) ? (
                <span className='user-fullName'>
                  {defaultTo(
                    establishments[0].id,
                    I18n.t('common.unknown.male')
                  )}
                </span>
              ) : (
                I18n.t('common.unknown.male')
              )
            },
            sorter: (a, b) =>
              defaultTo(get(a, 'establishments[0].id'), 0) -
              defaultTo(get(b, 'establishments[0].id'), 0)
          },
          establishment: {
            title: 'pages.restaurantOwners.table.columns.establishmentName',
            dataIndex: 'establishments',
            key: 'establishments',
            render: establishments => {
              return !isEmpty(establishments) ? (
                <span className='user-fullName'>
                  {defaultTo(
                    establishments[0].name,
                    I18n.t('common.unknown.male')
                  )}
                </span>
              ) : (
                I18n.t('common.unknown.male')
              )
            },
            sorter: (a, b) =>
              defaultTo(get(a, 'establishments[0].name'), '').localeCompare(
                defaultTo(get(b, 'establishments[0].name'), '')
              )
          },
          registerLevel: {
            title: 'pages.restaurantOwners.table.columns.registerLevel',
            dataIndex: 'registerLevel.name',
            key: 'registerLevel',
            render: registerLevel => {
              return (
                <span className='stripeState'>
                  {I18n.t(
                    `pages.restaurantOwners.table.columns.subscription.registerLevel.${registerLevel}`
                  )}
                </span>
              )
            },
            sorter: (a, b) =>
              defaultTo(get(a, 'registerLevel.name'), '').localeCompare(
                defaultTo(get(b, 'registerLevel.name'), '')
              )
          },
          subscription: {
            title: 'pages.restaurantOwners.table.columns.subscription.title',
            dataIndex: 'establishments[0].subscribeState',
            key: 'subscribeState',
            render: subscribeState => {
              let color = 'red'

              switch (subscribeState) {
                default:
                case 'FORCE_OK':
                case 'STRIPE_OK':
                case 'APPLE_OK':
                  color = 'green'
                  break

                case 'NO_PAYMENT':
                  color = 'orange'
                  break

                case 'STRIPE_PAYMENT_FAILURE':
                case 'APPLE_PAYMENT_EXPIRED':
                  color = 'red'
                  break
              }
              return (
                <span className='stripeState'>
                  {subscribeState && (
                    <Tag color={color} className='stripeState-type'>
                      {I18n.t(
                        `pages.restaurantOwners.table.columns.subscription.stripeState.${subscribeState}`
                      )}
                    </Tag>
                  )}
                </span>
              )
            },
            sorter: (a, b) => {
              let subscribeStateA = defaultTo(get(a, 'subscribeState'), '')
              if (
                subscribeStateA === 'STRIPE_OK' ||
                subscribeStateA === 'APPLE_OK'
              ) {
                subscribeStateA = '_' + subscribeStateA
              }
              let subscribeStateB = defaultTo(get(b, 'subscribeState'), '')
              if (
                subscribeStateB === 'STRIPE_OK' ||
                subscribeStateB === 'APPLE_OK'
              ) {
                subscribeStateB = '_' + subscribeStateB
              }
              return subscribeStateA.localeCompare(subscribeStateB)
            }
          },
          createdAt: {
            title: 'pages.restaurantOwners.table.columns.createdAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: createdAt => (
              <span className='user-id'>
                {createdAt !== undefined
                  ? I18n.t('date.formats.longDay', {
                      date: moment(createdAt).toDate()
                    })
                  : '-'}
              </span>
            ),
            sorter: (a, b) =>
              moment(get(a, 'createdAt', 0)).toDate() -
              moment(get(b, 'createdAt', 0)).toDate()
          }
        }
      }
    }
  }

  constructor(props) {
    super(props)

    // États initiaux
    this.state = {}
  }

  componentDidMount = () => {
    const {
      navigation: {
        state: { params }
      }
    } = this.props
    // Récupération des données de la vue
    this.fetchRestaurantOwners().then(({ body }) => {
      if (params.id) {
        const restaurantOwner = find(body, {
          establishments: [{ id: parseInt(params.id) }]
        })
        if (restaurantOwner) {
          this.getRestaurantOwner(restaurantOwner.id).then(({ body }) => {
            this.setState({ restaurantOwner: body, drawerOpened: true })
          })
        }
      }
    })
  }

  updateRestaurantOwner = () => {
    if (this.state.restaurantOwner !== undefined) {
      const restaurantOwner = find(this.props.restaurantOwners, {
        id: parseInt(this.state.restaurantOwner.id)
      })
      this.setState({ restaurantOwner: restaurantOwner })
    }
  }

  fetchRestaurantOwners = () => {
    return this.props.actions
      .fetchRestaurantOwners()
      .catch(() => message.errors('api.errors.user.fetch'))
  }

  getRestaurantOwner = id => {
    return this.props.actions
      .getRestaurantOwner({ id })
      .catch(() => message.errors('api.errors.user.fetch'))
  }

  /**
   * Demande de suppression de restaurateurs
   */
  askDeleteRestaurantOwner = restaurantOwner => {
    return new Promise((resolve, reject) => {
      confirm({
        title: I18n.t(
          'pages.restaurantOwners.components.modal.deleteRestaurantOwners.title'
        ),
        content: I18n.t(
          'pages.restaurantOwners.components.modal.deleteRestaurantOwners.content'
        ),
        okText: I18n.t('common.delete'),
        okType: 'danger',
        cancelText: I18n.t('common.cancel'),
        onOk: () => {
          this.deleteRestaurantOwner(restaurantOwner)
          resolve()
        },
        onCancel: () => {
          reject(Error('cancel'))
        },
        maskClosable: true
      })
    })
  }

  onClickRestaurantOwner = restaurantOwner => {
    this.getRestaurantOwner(restaurantOwner.id).then(({ body }) => {
      this.setState({ restaurantOwner: body, drawerOpened: true })
    })
  }

  /**
   * Suppression de restaurateurs
   */
  deleteRestaurantOwner = restaurantOwnerIds => {
    this.props.actions
      .deleteRestaurantOwners(restaurantOwnerIds)
      .then(() => {
        message.success(I18n.t(`api.success.restaurantOwners.deleteMany`))
      })
      .catch(() => {
        message.error(I18n.t(`api.errors.restaurantOwners.deleteMany`))
      })
  }

  handleCloseDrawer = restaurantOwner => {
    this.setState(
      {
        drawerOpened: false
      },
      () => unset(this.state, 'restaurantOwner')
    )
  }

  render() {
    const { tables, buttons, isGathering, restaurantOwners } = this.props
    const { restaurantOwner, drawerOpened } = this.state

    // Ajout des données aux tableaux
    tables.restaurantOwners.rows = restaurantOwners
    tables.restaurantOwners.multiDelete.onClick = this.askDeleteRestaurantOwner

    tables.restaurantOwners.multiDelete.title = count =>
      I18n.t('components.tableLayout.deleteMany', { count })

    // Ajout des actions au clic sur un ligne du tableau
    tables.restaurantOwners.onRow = (restaurantOwner, id) => ({
      onClick: () => this.onClickRestaurantOwner(restaurantOwner, id)
    })

    return (
      <main className='restaurantOwners screen'>
        {/* Tableau d'utilisateurs */}
        <TableLayout
          elementName='restaurantOwner'
          elementKind='male'
          buttons={[
            ...buttons,
            {
              label: I18n.t('pages.restaurantOwners.table.actions.export'),
              icon: 'download',
              type: 'default',
              href: baseURL + '/restaurant_owner/export'
            }
          ]}
          elementConsonant
          loading={isGathering}
          tables={tables}
        />

        {/* Affichage d'un utilisateur */}
        <Drawer
          onClose={this.handleCloseDrawer}
          restaurantOwner={restaurantOwner}
          updateRestaurantOwner={this.updateRestaurantOwner}
          visible={drawerOpened}
        />
      </main>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(RestaurantOwnersScreen, 'defaultProps', {})

  return {
    restaurantOwners: defaultTo(
      get(state, 'restaurantOwner.items'),
      defaultProps.restaurantOwners
    ),
    isGathering: defaultTo(
      get(state, 'restaurantOwner.isFetching'),
      defaultProps.isGathering
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...restaurantOwnerActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestaurantOwnersScreen)
