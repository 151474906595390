// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { includes } from 'lodash'
import React, { Component } from 'react'
import { Icon, Layout, Menu } from 'antd'
import { Link } from '@react-navigation/web'

// Components
import Images from 'Images'
import SubMenu from 'antd/lib/menu/SubMenu'

const { Sider } = Layout

class CustomSider extends Component {
  static propTypes = {
    theme: PropTypes.string,
    currentScreen: PropTypes.object,
    links: PropTypes.array,
    collapsible: PropTypes.bool
  }

  static defaultProps = {
    theme: 'light',
    collapsible: false,
    links: [
      // restaurantOwners
      {
        routeName: 'RestaurantOwners',
        title: I18n.t('pages.restaurantOwners.title'),
        icon: 'team',
        matchScreens: ['RestaurantOwners']
      },
      {
        routeName: 'Customers',
        title: I18n.t('pages.customers.title'),
        icon: 'user',
        matchScreens: ['Customers']
      },
      {
        routeName: 'Promotion',
        title: I18n.t('pages.promotion.title'),
        icon: 'book',
        children: [
          {
            routeName: 'PromotionCode',
            title: I18n.t('pages.promotion.code.title'),
            icon: 'barcode',
            matchScreens: ['PromotionCode']
          },
          {
            routeName: 'PromotionRedeem',
            title: I18n.t('pages.promotion.redeem.title'),
            icon: 'unordered-list',
            matchScreens: ['PromotionRedeem']
          }
        ]
      },
      {
        routeName: 'Statistics',
        title: I18n.t('pages.statistics.title'),
        icon: 'area-chart',
        matchScreens: ['Statistics']
      }
    ]
  }

  constructor(props) {
    super(props)

    this.state = {
      collapsed: false
    }
  }

  _handleCollapse = collapsed => {
    this.setState({ collapsed })
  }

  _renderMenuLink = ({
    title = '',
    routeName = '',
    key = routeName,
    icon = false,
    children
  }) => {
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span>
              <Icon type={icon} />
              <span>{title}</span>
            </span>
          }
        >
          {children.map(child => this._renderMenuLink(child))}
        </SubMenu>
      )
    }
    return (
      <Menu.Item key={key}>
        <Link routeName={routeName} params={{ id: undefined }}>
          {icon && <Icon type={icon} />}
          <span>{title}</span>
        </Link>
      </Menu.Item>
    )
  }

  _associatedScreens = (links, currentScreen) => {
    const associatedScreens = []
    links.forEach(({ children, matchScreens, routeName }) => {
      if (children) {
        const childrenAssociatedScreens = this._associatedScreens(
          children,
          currentScreen
        )
        childrenAssociatedScreens.forEach(associatedScreen => {
          associatedScreens.push(associatedScreen)
        })
        if (childrenAssociatedScreens.length > 0) {
          associatedScreens.push(routeName)
        }
      }
      if (includes(matchScreens, currentScreen)) {
        associatedScreens.push(routeName)
      }
    })
    return associatedScreens
  }

  _renderMenu = () => {
    const {
      currentScreen: { routeName: currentScreen },
      links
    } = this.props

    // Récupération des actifs en fonction du matchScreens
    const associatedScreens = this._associatedScreens(links, currentScreen)

    return (
      <Menu
        defaultSelectedKeys={associatedScreens}
        selectedKeys={associatedScreens}
        defaultOpenKeys={associatedScreens}
        mode='inline'
      >
        {links.map(this._renderMenuLink)}
      </Menu>
    )
  }

  render() {
    const { collapsed } = this.state
    const { theme, collapsible } = this.props

    const logo = collapsed ? Images.icon : Images.logo_white

    return (
      <Sider
        theme={theme}
        collapsible={collapsible}
        collapsed={collapsed}
        onCollapse={this._handleCollapse}
      >
        {/* Logo de l'application */}
        <Link routeName='Dashboard'>
          <div className='ant-layout-sider-logo'>
            <img
              className='ant-layout-sider-logo-image'
              src={logo}
              alt='logo'
            />
          </div>
        </Link>

        {/* Menu */}
        {this._renderMenu()}
      </Sider>
    )
  }
}

export default CustomSider
