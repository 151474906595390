import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme =>
  createStyles({
    card: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.border.main,
      borderRadius: 0
    }
  })
)
