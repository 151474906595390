import React from 'react'
import {
  AppBar,
  Button,
  FormControl,
  MenuItem,
  Select,
  Toolbar,
  Typography
} from '@material-ui/core'
import I18n from 'I18n'
import PropTypes from 'prop-types'
import { defaultTo, get, indexOf } from 'lodash'
import './Styles/RestaurantOwnerSelector.less'

const RestaurantOwnerSelector = ({
  restaurantOwner,
  restaurantOwners,
  onSelectRestaurantOwner,
  onEnableRestaurantOwner,
  onDisableRestaurantOwner
}) => {
  return (
    <AppBar
      position='static'
      color='transparent'
      elevation={0}
      className='statistics-header'
    >
      <Toolbar>
        <Typography className='statistics-header-label'>
          {I18n.t('pages.statistics.user.label')}
        </Typography>
        <FormControl variant='standard' className='statistics-header-select'>
          <Select
            value={restaurantOwner ? restaurantOwner.id : '0'}
            onChange={event => {
              restaurantOwner = restaurantOwners.find(
                restaurantOwner => restaurantOwner.id === event.target.value
              )
              onSelectRestaurantOwner(restaurantOwner)
            }}
          >
            <MenuItem value='0'>
              <em>{I18n.t('pages.statistics.user.none')}</em>
            </MenuItem>
            {restaurantOwners &&
              restaurantOwners
                .filter(
                  restaurantOwner =>
                    defaultTo(
                      get(restaurantOwner, 'establishments[0].status.name'),
                      undefined
                    ) === 'DONE'
                )
                .sort((a, b) =>
                  defaultTo(
                    get(a, 'establishments[0].name', ''),
                    ''
                  ).localeCompare(
                    defaultTo(get(b, 'establishments[0].name', ''), '')
                  )
                )
                .map(restaurantOwner => (
                  <MenuItem key={restaurantOwner.id} value={restaurantOwner.id}>
                    {I18n.t('pages.statistics.user.select', {
                      fullName: get(restaurantOwner, 'fullname', ''),
                      email: get(restaurantOwner, 'email', ''),
                      establishment: get(
                        restaurantOwner,
                        'establishments[0].name',
                        ''
                      ),
                      city: get(restaurantOwner, 'establishments[0].city', ''),
                      interpolation: { escapeValue: false }
                    })}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        {restaurantOwner && (
          <div className='statistics-header-action'>
            {indexOf(
              get(restaurantOwner, 'permissions', []),
              'api.statistics_access'
            ) !== -1 ? (
              <Button
                variant='outlined'
                className='button-error'
                onClick={onDisableRestaurantOwner}
              >
                {I18n.t('pages.statistics.user.disable')}
              </Button>
            ) : (
              <Button
                variant='contained'
                color='primary'
                onClick={onEnableRestaurantOwner}
              >
                {I18n.t('pages.statistics.user.enable')}
              </Button>
            )}
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

RestaurantOwnerSelector.propTypes = {
  restaurantOwner: PropTypes.object,
  restaurantOwners: PropTypes.array,
  onSelectRestaurantOwner: PropTypes.func,
  onEnableRestaurantOwner: PropTypes.func,
  onDisableRestaurantOwner: PropTypes.func
}

export default RestaurantOwnerSelector
