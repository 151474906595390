import { createResource } from 'redux-rest-resource'
import { baseURL } from './'
import { cloneDeep, includes, remove } from 'lodash'

export const { types, actions, rootReducer } = createResource({
  name: 'promotionRedeem',
  url: `${baseURL}`,
  credentials: 'include',
  actions: {
    fetch: { method: 'GET', gerundName: 'fetching', url: './promotion/redeem' },
    delete: {
      method: 'DELETE',
      gerundName: 'deleting',
      url: './promotion/redeem/:id',
      reduce: ({ items, archives, ...state }, { context: deleted, status }) => {
        const isDeleting = status === 'pending'

        if (status === 'resolved') {
          // Suppression des données archivées du store
          remove(items, ({ id }) => includes(deleted, id))
        }

        return {
          ...state,
          items: cloneDeep(items),
          isDeleting
        }
      }
    }
  }
})
