import { defaultTo, find, get, isNil } from 'lodash'
import { bindActionCreators } from 'redux'
import { actions as promotionCodeActions } from 'Resources/PromotionCodeResource'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import I18n from 'i18next'
import { TableLayout } from 'Components'
import PropTypes from 'prop-types'
import { Button, message, Modal } from 'antd'
import moment from 'moment'
import { Drawer } from './Components'

const { confirm } = Modal

class PromotionCodeScreen extends Component {
  static propTypes = {
    // Données de la vue
    promotionCodes: PropTypes.array,

    // Status de récupération des données
    isGathering: PropTypes.bool,

    // Actions d'API
    actions: PropTypes.object,

    // Boutons à afficher en haut à droite du tableau
    buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // Tableaux à afficher sur la page
    tables: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    navigation: PropTypes.any
  }

  static defaultProps = {
    // Données de la vue
    promotionCodes: [],

    // Status de récupération des données
    isGathering: true,

    // Boutons à afficher en haut à droite du tableau
    buttons: [],

    // Tableaux à afficher sur la page
    tables: {
      promotionCodes: {
        rowKey: 'id',
        locale: {
          emptyText: I18n.t('pages.promotion.code.table.empty')
        },
        pagination: {
          position: 'both',
          showSizeChanger: true
        },
        columns: {
          id: {
            title: 'pages.promotion.code.table.columns.id',
            dataIndex: 'id',
            key: 'id',
            render: id => (
              <span className='promotion-code-id'>
                {!isNil(id) ? id : I18n.t('common.unknown.male')}
              </span>
            ),
            sorter: (a, b) =>
              defaultTo(get(a, 'id'), 0) - defaultTo(get(b, 'id'), 0)
          },
          code: {
            title: 'pages.promotion.code.table.columns.code',
            dataIndex: 'code',
            key: 'code',
            render: code => (
              <span className='promotion-code-code'>
                {!isNil(code) ? code : I18n.t('common.unknown.male')}
              </span>
            ),
            sorter: (a, b) =>
              defaultTo(get(a, 'code'), '').localeCompare(
                defaultTo(get(b, 'code'), '')
              )
          },
          freePeriod: {
            title: 'pages.promotion.code.table.columns.freePeriod',
            dataIndex: 'freePeriod',
            key: 'freePeriod',
            render: freePeriod => (
              <span className='promotion-code-freePeriod'>
                {freePeriod.type === 'date'
                  ? !isNil(freePeriod.value)
                    ? I18n.t(
                        'pages.promotion.code.table.columns.freeExpireAt',
                        {
                          date: moment(freePeriod.value).toDate()
                        }
                      )
                    : '-'
                  : I18n.t('pages.promotion.code.table.columns.freeDays', {
                      value: freePeriod.value
                    })}
              </span>
            )
          },
          expireAt: {
            title: 'pages.promotion.code.table.columns.expireAt',
            dataIndex: 'expireAt',
            key: 'expireAt',
            render: expireAt => (
              <span className='promotion-code-expireAt'>
                {!isNil(expireAt)
                  ? I18n.t('date.formats.longDay', {
                      date: moment(expireAt).toDate()
                    })
                  : '-'}
              </span>
            ),
            sorter: (a, b) =>
              moment(get(a, 'expireAt', 0)).toDate() -
              moment(get(b, 'expireAt', 0)).toDate()
          },
          createdAt: {
            title: 'pages.promotion.code.table.columns.createdAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: createdAt => (
              <span className='promotion-code-createdAt'>
                {createdAt !== undefined
                  ? I18n.t('date.formats.longDay', {
                      date: moment(createdAt).toDate()
                    })
                  : '-'}
              </span>
            ),
            sorter: (a, b) =>
              moment(get(a, 'createdAt', 0)).toDate() -
              moment(get(b, 'createdAt', 0)).toDate()
          },
          updatedAt: {
            title: 'pages.promotion.code.table.columns.updatedAt',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: createdAt => (
              <span className='promotion-code-updatedAt'>
                {createdAt !== undefined
                  ? I18n.t('date.formats.longDay', {
                      date: moment(createdAt).toDate()
                    })
                  : '-'}
              </span>
            ),
            sorter: (a, b) =>
              moment(get(a, 'updatedAt', 0)).toDate() -
              moment(get(b, 'updatedAt', 0)).toDate()
          },
          actions: {
            title: 'pages.promotion.code.table.columns.actions',
            key: 'actions',
            dataIndex: 'actions',
            render: (actions, promotionCode) => (
              <span className='promotion-code-action'>
                <Button type='default' onClick={actions._edit}>
                  {I18n.t('pages.promotion.code.table.actions.edit')}
                </Button>
                {(isNil(promotionCode.expireAt) ||
                  moment(promotionCode.expireAt) > moment()) && (
                  <Button type='danger' onClick={actions._disable}>
                    {I18n.t('pages.promotion.code.table.actions.disable')}
                  </Button>
                )}
              </span>
            )
          }
        }
      }
    }
  }

  constructor(props) {
    super(props)

    // États initiaux
    this.state = {}
  }

  componentDidMount = () => {
    const {
      navigation: {
        state: { params }
      }
    } = this.props
    // Récupération des données de la vue
    this.fetchPromotionCodes().then(({ body }) => {
      if (params.id) {
        const promotionCode = find(body, { id: parseInt(params.id) })
        if (promotionCode) {
          this.setState({
            drawer: promotionCode
          })
        }
      }
    })
  }

  /**
   * Suppression de l'abonnement
   */
  disablePromotionCode = promotionCode => {
    this.props.actions
      .disablePromotionCode({ id: promotionCode.id })
      .then(() => {
        message.success(
          I18n.t(`api.success.restaurantOwners.deleteSubscription`)
        )
      })
      .catch(() => {
        message.error(I18n.t(`api.errors.restaurantOwners.deleteSubscription`))
      })
  }

  _disable = promotionCode => {
    confirm({
      title: I18n.t(
        'pages.promotion.code.table.components.modal.disable.title'
      ),
      content: I18n.t(
        'pages.promotion.code.table.components.modal.disable.content'
      ),
      okText: I18n.t('common.disable'),
      okType: 'danger',
      cancelText: I18n.t('common.cancel'),
      onOk: () => {
        this.disablePromotionCode(promotionCode)
      },
      maskClosable: true
    })
  }

  _edit = promotionCode => {
    this.setState({
      drawer: promotionCode
    })
  }

  /**
   * Récupère les clients
   */
  fetchPromotionCodes = () => {
    return this.props.actions
      .fetchPromotionCodes()
      .catch(() => message.errors('api.errors.user.fetch'))
  }

  _add = () => {
    this.setState({
      drawer: {}
    })
  }

  render() {
    const { tables, buttons, isGathering, promotionCodes } = this.props
    const { drawer } = this.state

    // Ajout des données aux tableaux
    promotionCodes.forEach(promotionCode => {
      promotionCode.freePeriod = {
        type: 'days',
        value: promotionCode.freeDays
      }
      if (isNil(promotionCode.freeDays)) {
        promotionCode.freePeriod = {
          type: 'date',
          value: promotionCode.freeExpireAt
        }
      }
      promotionCode.actions = {
        _disable: () => this._disable(promotionCode),
        _edit: () => this._edit(promotionCode)
      }
    })
    tables.promotionCodes.rows = promotionCodes

    // Ajout des actions au clic sur un ligne du tableau
    // tables.customers.onRow = (customer, id) => ({
    //   onClick: () => this.onClickCustomer(customer, id)
    // })

    return (
      <main className='promotion-code screen'>
        {/* Tableau d'utilisateurs */}
        <TableLayout
          elementName='promotionCode'
          elementKind='male'
          buttons={[
            ...buttons,
            {
              label: I18n.t('pages.promotion.code.table.actions.create'),
              icon: 'file-add',
              type: 'primary',
              action: this._add
            }
          ]}
          elementConsonant
          loading={isGathering}
          tables={tables}
        />

        {/* Affichage d'un utilisateur */}
        <Drawer
          onClose={this.handleCloseDrawer}
          promotionCode={drawer}
          visible={drawer !== undefined}
        />
      </main>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(PromotionCodeScreen, 'defaultProps', {})
  return {
    promotionCodes: defaultTo(
      get(state, 'promotionCode.items'),
      defaultProps.promotionCode
    ),
    isGathering: defaultTo(
      get(state, 'promotionCode.isFetching'),
      defaultProps.isGathering
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...promotionCodeActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionCodeScreen)
