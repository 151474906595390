import { createResource } from 'redux-rest-resource'
import { baseURL } from './'

/*
  Ceci est un exemple de resource mais pas utilisée
 */
export const { types, actions, rootReducer } = createResource({
  name: 'auth',
  url: `${baseURL}/auth`,
  actions: {
    login: { method: 'POST', gerundName: 'loggingIn', url: './login' },
    forgotten: {
      method: 'POST',
      gerundName: 'askingForgotten',
      url: './login/password-forgotten'
    },
    firstLogin: {
      method: 'POST',
      gerundName: 'firstLoggingIn',
      url: './login/first-login'
    },
    checkLogin: {
      method: 'POST',
      gerundName: 'checkingLogin',
      url: './login/check'
    },
    checkCode: {
      method: 'POST',
      gerundName: 'checkingCode',
      url: './login/code'
    },
    setPassword: {
      method: 'POST',
      gerundName: 'settingPassword',
      url: './login/set-password'
    },
    createPassword: {
      method: 'POST',
      gerundName: 'addingPassword',
      url: './login/password-change'
    },
    logout: { method: 'GET', gerundName: 'loggingOut', url: './logout' },
    set: {
      isPure: true,
      reduce: (state, { context: credentials }) => ({
        ...state,
        item: credentials
      })
    },
    // Vidage du reducer
    reset: {
      isPure: true,
      reduce: state => ({ ...state, item: null })
    }
  },
  credentials: 'include'
})
