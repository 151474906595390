import { createResource } from 'redux-rest-resource'
import { baseURL } from './'
import { cloneDeep, map } from 'lodash'

export const { types, actions, rootReducer } = createResource({
  name: 'promotionCode',
  url: `${baseURL}`,
  credentials: 'include',
  actions: {
    fetch: { method: 'GET', gerundName: 'fetching', url: './promotion/code' },
    add: {
      method: 'POST',
      gerundName: 'adding',
      url: './promotion/code',
      reduce: ({ items, ...state }, { body: newItem, status }) => {
        // Si la requête est terminée
        if (status === 'resolved') {
          items.push(newItem)
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    update: {
      method: 'PATCH',
      gerundName: 'updating',
      url: './promotion/code/:id',
      reduce: ({ items, ...state }, { body: updatedItem, status }) => {
        // Si la requête est terminée
        if (status === 'resolved') {
          // Mise à jour du local item
          items = map(items, item => {
            if (item.id === updatedItem.id) {
              return updatedItem
            } else {
              return item
            }
          })
        }

        return { ...state, items }
      }
    },
    disable: {
      method: 'DELETE',
      gerundName: 'disabling',
      url: './promotion/code/:id',
      reduce: ({ items, ...state }, { body: updatedItem, status }) => {
        // Si la requête est terminée
        if (status === 'resolved') {
          // Mise à jour du local item
          items = map(items, item => {
            if (item.id === updatedItem.id) {
              return updatedItem
            } else {
              return item
            }
          })
        }

        return { ...state, items }
      }
    }
  }
})
