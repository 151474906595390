// Libraries
import zxcvbn from 'zxcvbn'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Popover, Progress, Alert } from 'antd'
import { isEmpty, get, map, defaultTo } from 'lodash'

// Styles
import './Styles/StrengthIndicator.less'
import I18n from 'I18n'

class StrengthIndicator extends Component {
  static propTypes = {
    children: PropTypes.element,
    visible: PropTypes.bool,
    placement: PropTypes.oneOf([
      'topLeft',
      'top',
      'topRight',
      'leftTop',
      'left',
      'leftBottom',
      'rightTop',
      'right',
      'rightBottom',
      'bottomLeft',
      'bottom',
      'bottomRight'
    ]),
    value: PropTypes.string
  }

  static defaultProps = {
    visible: true,
    placement: 'right',
    value: ''
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  /**
   * Rendu de la pop over
   */
  renderPopover = () => {
    const { value } = this.props

    // Évaluation du mot de passe
    const passwordReview = zxcvbn(value)
    const scorePercent = (get(passwordReview, 'score') / 4) * 100

    let status

    switch (defaultTo(get(passwordReview, 'score'), 0)) {
      default:
      case 0:
      case 1:
        status = 'weak'
        break
      case 2:
        status = 'fair'
        break
      case 3:
        status = 'good'
        break
      case 4:
        status = 'strong'
        break
    }

    return (
      <div className='strength-indicator'>
        {/* Indicateur de robustesse */}
        <p
          className={`strength-indicator-status strength-indicator-status-${status}`}
        >
          {I18n.t(`components.strengthIndicator.status.${status}`)}
        </p>

        {/* Pourcentage de robustesse */}
        <Progress
          showInfo={false}
          percent={scorePercent}
          className={`strength-indicator-progress strength-indicator-progress-${status}`}
        />

        {/* Avertissement */}
        {!isEmpty(get(passwordReview, 'feedback.warning')) && (
          <Alert
            className='strength-indicator-warning'
            title={get(passwordReview, 'feedback.warning.message')}
            message={I18n.t(
              `components.strengthIndicator.messages.${get(
                passwordReview,
                'feedback.warning.code'
              )}`
            )}
            type='warning'
          />
        )}

        {/* Suggestions */}
        <ul className='strength-indicator-suggestions'>
          {map(
            defaultTo(get(passwordReview, 'feedback.suggestions'), []),
            ({ message, code }) => (
              <li
                className='strength-indicator-suggestions-item'
                title={message}
              >
                {I18n.t(`components.strengthIndicator.messages.${code}`)}
              </li>
            )
          )}
        </ul>
      </div>
    )
  }

  render() {
    const { children, visible, placement, value } = this.props

    return (
      <Popover
        placement={placement}
        content={this.renderPopover()}
        visible={visible && !isEmpty(value)}
      >
        {children}
      </Popover>
    )
  }
}

export default StrengthIndicator
