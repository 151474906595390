// Libraries
import { createNavigator, StackRouter } from '@react-navigation/core'
import I18n from 'i18next'

// Utils
import { prepareStack } from 'Navigation/Utils'

// Layout
import { AuthenticatedLayout } from 'Navigation/Layout'

// Views
import Profile from 'Views/Profile'
import RestaurantOwners from 'Views/RestaurantOwners'
import Customers from 'Views/Customers'
import PromotionCode from 'Views/Promotion/Code'
import PromotionRedeem from 'Views/Promotion/Redeem'
import Statistics from 'Views/Statistics'

// Stacks
export const Stack = StackRouter(
  prepareStack({
    Profile: {
      screen: Profile,
      path: 'profile',
      navigationOptions: {
        title: I18n.t('pages.profile.title')
      }
    },
    RestaurantOwners: {
      screen: RestaurantOwners,
      path: 'restaurantOwners',
      navigationOptions: {
        title: I18n.t('pages.restaurantOwners.title')
      }
    },
    Customers: {
      screen: Customers,
      path: 'customers',
      navigationOptions: {
        title: I18n.t('pages.customers.title')
      }
    },
    PromotionCode: {
      screen: PromotionCode,
      path: 'promotion/code',
      navigationOptions: {
        title:
          I18n.t('pages.promotion.title') +
          ' / ' +
          I18n.t('pages.promotion.code.title')
      }
    },
    PromotionRedeem: {
      screen: PromotionRedeem,
      path: 'promotion/redeem',
      navigationOptions: {
        title:
          I18n.t('pages.promotion.title') +
          ' / ' +
          I18n.t('pages.promotion.redeem.title')
      }
    },
    Statistics: {
      screen: Statistics,
      path: 'statistics',
      navigationOptions: {
        title: I18n.t('pages.statistics.title')
      }
    }
  }),
  {
    initialRouteName: 'RestaurantOwners'
  }
)

// Navigators
const navigationConfig = {}
const Navigator = createNavigator(AuthenticatedLayout, Stack, navigationConfig)

export default Navigator
