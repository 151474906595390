// Libraries
import React from 'react'
import { filter, isObject, keys, mapValues } from 'lodash'

/**
 * Filtre les données en fonction du motif renseigné dans le champs de recherche
 *
 * Retourne un tableau contenant toutes les données en fonction de la recherche de l'utilisateur
 * @return {array}
 */
export const searchData = (data, searchText) => {
  searchText = searchText.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')

  // Création de l'expression rationnelle avec la recherche de l'utilisateur
  const regExp = new RegExp(searchText, 'gi')

  // Données filtrées sur la recherche
  return filter(data, element => {
    let found = false

    // Pour chaque champs recherche avec le mot clé
    keys(element).forEach(key => {
      found = found || JSON.stringify(element[key]).match(regExp)
    })
    return found
  })
}

/**
 * Shorten number to thousands, millions, billions, etc.
 * http://en.wikipedia.org/wiki/Metric_prefix
 *
 * @param {number} num Number to shorten.
 * @param {number} [digits=0] The number of digits to appear after the decimal point.
 * @returns {string|number}
 *
 * @example
 * // returns '12.5k'
 * shortenLargeNumber(12543, 1)
 *
 * @example
 * // returns '-13k'
 * shortenLargeNumber(-12567)
 *
 * @example
 * // returns '51M'
 * shortenLargeNumber(51000000)
 *
 * @example
 * // returns 651
 * shortenLargeNumber(651)
 *
 * @example
 * // returns 0.12345
 * shortenLargeNumber(0.12345)
 */
export function shortenLargeNumber(num, digits) {
  const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
  let decimal

  for (var i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1)

    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i]
    }
  }

  return num
}

/**
 * Permet de tronquer une string avec un nombre de caractères défini
 * @param {String} string
 * @param {int} n
 * @param {bool} withSpan
 */
export function truncateString(string = '', n, withSpan) {
  if (string.length <= n) {
    return string
  }

  const subString = string.substr(0, n - 1)

  const finalString = subString.substr(0, subString.lastIndexOf(' '))

  const isTruncated = string.length !== finalString.length

  return withSpan ? (
    <span className={isTruncated ? 'truncated' : 'original'}>
      {finalString}
    </span>
  ) : (
    finalString
  )
}

// Map values deep
export function createFormFields(Form, object) {
  return mapValues(object, value => {
    if (isObject(value)) {
      return createFormFields(Form, value)
    } else {
      return Form.createFormField({ value })
    }
  })
}
