// Reactotron
import 'Config/ReactotronConfig'

// Libraries
import React, { Component } from 'react'
import { Provider } from 'react-redux'
import 'I18n'

// Redux
import createStore from 'Redux'

// Resources
import configureResources from '../../Resources'

// Views
import RootContainer from './RootContainer'

// Styles
import 'Theme/styles.less'

// Création du store
const store = createStore()

class App extends Component {
  componentDidMount() {
    // Configuration de redux-rest-resource
    configureResources()
  }

  render() {
    return (
      <Provider store={store}>
        <RootContainer />
      </Provider>
    )
  }
}

export default App
