// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  defaultTo,
  find,
  get,
  indexOf,
  isEmpty,
  isNil,
  join,
  map,
  pick
} from 'lodash'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import {
  Avatar,
  Button,
  Drawer,
  Icon,
  List,
  message,
  Modal,
  Typography
} from 'antd'
import moment from 'moment'
// Common components
import { ImageCarouselModal } from 'Components/Modals/ImageCarousel'
// Styles
import './Styles/RestaurantOwnerDrawer.less'
// Ressources
import { actions as RestaurantOwnerActions } from 'Resources/RestaurantOwnerResource'
import { actions as statisticsActions } from 'Resources/StatisticsResource'
import { actions as eventsActions } from 'Resources/EventsResource'
import { actions as bookingActions } from 'Resources/BookingResource'
import { actions as orderActions } from 'Resources/OrderResource'
import { actions as multiPhotoActions } from 'Resources/MultiPhotoResource'
import { actions as autoDeleteActions } from 'Resources/AutoDeleteResource'
import { actions as conversationActions } from 'Resources/ConversationResource'
import { actions as gamingActions } from 'Resources/GamingResource'

const { confirm } = Modal

class RestaurantOwnerDrawer extends Component {
  static propTypes = {
    // Données de la vue
    restaurantOwner: PropTypes.object,
    menuBoards: PropTypes.array,
    actions: PropTypes.object,
    isFetchingMenuBoards: PropTypes.bool,

    // Fonctions
    onClose: PropTypes.func,
    updateRestaurantOwner: PropTypes.func,
    onCancel: PropTypes.func
  }

  static defaultProps = {
    // Données de la vue
    restaurantOwner: {},
    menuBoards: []
  }

  constructor(props) {
    super(props)

    // États initiaux
    this.state = {
      visible: get(props, 'visible'),
      childrenDrawer: false,
      openedModal: ''
    }
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      visible: get(nextProps, 'visible')
    })
  }

  /**
   * Méthode pour cacher le drawer
   */
  handleClose = () => {
    const { onClose } = this.props

    this.setState({ visible: false }, onClose)
  }

  /**
   * Méthode pour cacher la modal des photos
   *
   */
  handleCancel = () => {
    const { onCancel } = this.props

    this.setState({ openedModal: '' }, onCancel)
  }

  renderDescriptionItem = (name, value) => (
    <p className='restaurantOwner-profile-description-item'>
      <span className='restaurantOwner-profile-description-item-name'>
        {I18n.t(`pages.restaurantOwners.components.drawer.description.${name}`)}
      </span>
      <span className='restaurantOwner-profile-description-item-value'>
        {defaultTo(value, I18n.t('common.unknown.male'))}
      </span>
    </p>
  )

  renderLinkItem = (name, value) => (
    <p className='restaurantOwner-profile-description-item'>
      <span className='restaurantOwner-profile-description-item-name'>
        {I18n.t(`pages.restaurantOwners.components.drawer.description.${name}`)}
      </span>
      <span className='restaurantOwner-profile-description-item-value link'>
        <a
          href={defaultTo(value, '')}
          target='_blank'
          rel='noopener noreferrer'
        >
          {defaultTo(value, I18n.t('common.unknown.male'))}
        </a>
        <Button
          shape='circle'
          icon='copy'
          onClick={() => this.copyToClipboard(defaultTo(value, ''))}
        />
      </span>
    </p>
  )

  copyToClipboard = async text => {
    await navigator.clipboard.writeText(text)
  }

  /**
   * Affichage de l'adresse complète
   */
  renderCustomDescriptionItem = (name, value) => {
    const fullAddress = pick(
      get(value, 'establishments[0]'),
      'address',
      'zipCode',
      'city',
      'country'
    )
    return (
      <p className='restaurantOwner-profile-description-item'>
        <span className='restaurantOwner-profile-description-item-name'>
          {I18n.t(
            'pages.restaurantOwners.components.drawer.description.fullAddress'
          )}
        </span>
        <span className='restaurantOwner-profile-description-item-value'>
          {!isEmpty(fullAddress) ? (
            `${get(fullAddress, 'address', '')} ${get(
              fullAddress,
              'zipCode',
              ''
            )} ${get(fullAddress, 'city', '')} ${get(
              fullAddress,
              'country',
              ''
            )}`
          ) : (
            <span>{I18n.t('common.unknown.male')}</span>
          )}
        </span>
      </p>
    )
  }

  /**
   * Affichage de la partie des url
   */
  renderUrlDescriptionItem = (name, value) => {
    return (
      <Fragment>
        <p className='restaurantOwner-profile-description-item'>
          <span className='restaurantOwner-profile-description-item-name'>
            {I18n.t(
              'pages.restaurantOwners.components.drawer.description.urls.facebook'
            )}
          </span>
          <span className='restaurantOwner-profile-description-item-value'>
            {!isEmpty(get(value, 'facebookUrl')) ? (
              <a
                href={get(value, 'facebookUrl')}
                target='_blank'
                rel='noopener noreferrer'
              >
                {get(value, 'facebookUrl')}
              </a>
            ) : (
              <span>{I18n.t('common.unknown.male')}</span>
            )}
          </span>
        </p>

        <p className='restaurantOwner-profile-description-item'>
          <span className='restaurantOwner-profile-description-item-name'>
            {I18n.t(
              'pages.restaurantOwners.components.drawer.description.urls.instagram'
            )}
          </span>
          <span className='restaurantOwner-profile-description-item-value'>
            {!isEmpty(get(value, 'instagramUrl')) ? (
              <a
                href={get(value, 'instagramUrl')}
                target='_blank'
                rel='noopener noreferrer'
              >
                {get(value, 'instagramUrl')}
              </a>
            ) : (
              <span>{I18n.t('common.unknown.male')}</span>
            )}
          </span>
        </p>
        <p className='restaurantOwner-profile-description-item'>
          <span className='restaurantOwner-profile-description-item-name'>
            {I18n.t(
              'pages.restaurantOwners.components.drawer.description.urls.mealandmeet'
            )}
          </span>
          <span className='restaurantOwner-profile-description-item-value'>
            {!isEmpty(get(value, 'mealandmeetUrl')) ? (
              <a
                href={get(value, 'mealandmeetUrl')}
                target='_blank'
                rel='noopener noreferrer'
              >
                {get(value, 'mealandmeetUrl')}
              </a>
            ) : (
              <span>{I18n.t('common.unknown.male')}</span>
            )}
          </span>
        </p>
      </Fragment>
    )
  }

  /**
   * Affichage des heures d'ouvertures
   */
  renderBusinessHoursDescriptionItem = (name, value) => {
    const hoursPaths = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ]

    return map(hoursPaths, (path, index) => {
      const hoursRanges = defaultTo(get(value, path), [])

      return (
        <div className='restaurantOwner-profile-description-item' key={index}>
          <span className='restaurantOwner-profile-description-item-name'>
            {I18n.t(
              `pages.restaurantOwners.components.drawer.description.${path}`
            )}
          </span>

          {isNil(hoursRanges) || isEmpty(hoursRanges) ? (
            <span className='restaurantOwner-profile-description-item-value hours'>
              {I18n.t(
                `pages.restaurantOwners.components.drawer.description.closed`
              )}
            </span>
          ) : (
            <ol className='restaurantOwner-profile-description-item-value hours'>
              {map(hoursRanges, (range, index) => (
                <li className='hours-item' key={index}>
                  <span className='hours-item-start'>
                    {moment(get(range, 'startTime'), 'HH:mm')
                      .utc()
                      .format('LT')}
                  </span>
                  <span className='hours-item-end'>
                    {moment(get(range, 'endTime'), 'HH:mm')
                      .utc()
                      .format('LT')}
                  </span>
                </li>
              ))}
            </ol>
          )}
        </div>
      )
    })
  }

  /**
   * Affichage du type de nourriture
   *
   */
  renderFoodTypeDescriptionItem = (name, value) => {
    return (
      <p className='restaurantOwner-profile-description-item'>
        <span className='restaurantOwner-profile-description-item-name'>
          {I18n.t(
            `pages.restaurantOwners.components.drawer.description.${name}.label`
          )}
        </span>
        <span className='restaurantOwner-profile-description-item-value'>
          {!isEmpty(value)
            ? join(value, ' - ')
            : I18n.t(
                'pages.restaurantOwners.components.drawer.description.foodType.noFood'
              )}
        </span>
      </p>
    )
  }

  /**
   * Affichage des photos des menus
   *
   */
  renderPictureDescriptionItem = (name, value) => {
    const currentMenuBoard = find(value, ['isCurrent', true])

    return (
      <Fragment>
        <div className='restaurantOwner-profile-description-item-itemMenu'>
          <Fragment>
            <h3 className='restaurantOwner-profile-description-item-name-menuTitle'>
              {I18n.t(
                'pages.restaurantOwners.components.drawer.description.menuBoard.currentMenu'
              )}
            </h3>
            <span className='restaurantOwner-profile-description-item'>
              {isNil(get(currentMenuBoard, 'picture')) ? (
                I18n.t(
                  'pages.restaurantOwners.components.drawer.description.menuBoard.noCurrentMenu'
                )
              ) : (
                <img
                  src={`${get(currentMenuBoard, 'picture')}`}
                  alt=''
                  className='restaurantOwner-profile-description-item-menuBoard'
                />
              )}
            </span>
          </Fragment>
        </div>
        {!isEmpty(value) && (
          <Fragment>
            <Button
              type='primary'
              onClick={this.openCarousel}
              className='restaurantOwner-profile-details-information-openCarousel'
            >
              {I18n.t(
                'pages.restaurantOwners.components.drawer.description.menuBoard.menuBoardsList.buttonLabel'
              )}
            </Button>
          </Fragment>
        )}
      </Fragment>
    )
  }

  /**
   * Ouvre le carousel des photos
   *
   */
  openCarousel = () => {
    this.setState({ openedModal: 'openCarousel' })
  }

  /**
   * Ouvre le drawer des menus
   */
  openMenuBoards = () => {
    const { restaurantOwner: id, actions } = this.props
    actions.fetchMenuBoardsRestaurantOwner(id).then(body => {
      this.setState({ childrenDrawer: true })
    })
  }

  /**
   * Gère le state du Drawer
   */
  onChildrenDrawerClose = () => {
    this.setState({
      childrenDrawer: false
    })
  }

  /**
   * Demande de désabonnement du restaurateur
   */
  askDeleteSubscription = restaurantOwner => {
    confirm({
      title: I18n.t(
        'pages.restaurantOwners.components.modal.deleteSubscriptionRestaurantOwner.title'
      ),
      content: I18n.t(
        'pages.restaurantOwners.components.modal.deleteSubscriptionRestaurantOwner.content'
      ),
      okText: I18n.t('common.delete'),
      okType: 'danger',
      cancelText: I18n.t('common.cancel'),
      onOk: () => {
        get(restaurantOwner, 'establishments[0].id')
        this.deleteSubscription(restaurantOwner)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de désabonnement du restaurateur
   */
  askForceEnableSubscription = restaurantOwner => {
    confirm({
      icon: 'warning',
      title: I18n.t(
        'pages.restaurantOwners.components.modal.forceEnableSubscriptionRestaurantOwner.title'
      ),
      content: I18n.t(
        'pages.restaurantOwners.components.modal.forceEnableSubscriptionRestaurantOwner.content'
      ),
      okText: I18n.t('common.enable'),
      okType: 'primary',
      cancelText: I18n.t('common.cancel'),
      onOk: () => {
        this.forceEnableSubscription(restaurantOwner)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de désabonnement du restaurateur
   */
  askForceDisableSubscription = restaurantOwner => {
    confirm({
      icon: 'warning',
      title: I18n.t(
        'pages.restaurantOwners.components.modal.forceDisableSubscriptionRestaurantOwner.title'
      ),
      content: I18n.t(
        'pages.restaurantOwners.components.modal.forceDisableSubscriptionRestaurantOwner.content'
      ),
      okText: I18n.t('common.disable'),
      okType: 'danger',
      cancelText: I18n.t('common.cancel'),
      onOk: () => {
        this.forceDisableSubscription(restaurantOwner)
      },
      maskClosable: true
    })
  }

  /**
   * Suppression de l'abonnement
   */
  deleteSubscription = restaurantOwner => {
    this.props.actions
      .deleteSubscriptionRestaurantOwner({ id: restaurantOwner.id })
      .then(() => {
        message.success(
          I18n.t(`api.success.restaurantOwners.deleteSubscription`)
        )
      })
      .catch(() => {
        message.error(I18n.t(`api.errors.restaurantOwners.deleteSubscription`))
      })
  }

  /**
   * Suppression de l'abonnement
   */
  forceEnableSubscription = restaurantOwner => {
    this.props.actions
      .forceEnableSubscriptionRestaurantOwner({
        id: get(restaurantOwner, 'establishments[0].id')
      })
      .then(() => {
        message.success(I18n.t(`api.success.restaurantOwners.forceEnable`))
      })
      .catch(() => {
        message.error(I18n.t(`api.errors.restaurantOwners.deleteSubscription`))
      })
  }

  /**
   * Suppression de l'abonnement
   */
  forceDisableSubscription = restaurantOwner => {
    this.props.actions
      .forceDisableSubscriptionRestaurantOwner({
        id: get(restaurantOwner, 'establishments[0].id')
      })
      .then(() => {
        message.success(I18n.t(`api.success.restaurantOwners.forceDisable`))
      })
      .catch(() => {
        message.error(I18n.t(`api.errors.restaurantOwners.deleteSubscription`))
      })
  }

  getRestaurantsApi = () => {
    const { REACT_APP_RESTAURANTS_ENDPOINT } = process.env
    if (REACT_APP_RESTAURANTS_ENDPOINT === undefined) {
      throw new Error('REACT_APP_RESTAURANTS_ENDPOINT must be defined')
    }

    return REACT_APP_RESTAURANTS_ENDPOINT
  }

  getMenuLink = restaurantOwner => {
    const restaurantsApi = this.getRestaurantsApi()
    return restaurantsApi + '/menu/' + get(restaurantOwner, 'id')
  }

  getQrCodeLink = restaurantOwner => {
    const restaurantsApi = this.getRestaurantsApi()
    return restaurantsApi + '/qrcode/' + get(restaurantOwner, 'id')
  }

  getSlateLink = restaurantOwner => {
    const restaurantsApi = this.getRestaurantsApi()
    return restaurantsApi + '/ardoise/' + get(restaurantOwner, 'id')
  }

  handleEnableRestaurantOwner = feature => {
    const { restaurantOwner, updateRestaurantOwner } = this.props
    switch (feature) {
      case 'statistics':
        this.props.actions
          .enableStatistics({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(I18n.t('pages.statistics.api.enable.success'))
          })
          .catch(() => {
            message.error(I18n.t('pages.statistics.api.enable.error'))
          })
        break
      case 'events':
        this.props.actions
          .enableEvents({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.events.enable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.events.enable.error'
              )
            )
          })
        break
      case 'booking':
        this.props.actions
          .enableBooking({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.booking.enable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.booking.enable.error'
              )
            )
          })
        break
      case 'order':
        this.props.actions
          .enableOrder({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.order.enable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.order.enable.error'
              )
            )
          })
        break
      case 'multi_photo':
        this.props.actions
          .enableMultiPhoto({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.multiPhoto.enable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.multiPhoto.enable.error'
              )
            )
          })
        break
      case 'auto_delete':
        this.props.actions
          .enableAutoDelete({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.autoDelete.enable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.autoDelete.enable.error'
              )
            )
          })
        break
      case 'conversation':
        this.props.actions
          .enableConversation({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.conversation.enable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.conversation.enable.error'
              )
            )
          })
        break
      case 'gaming':
        this.props.actions
          .enableGaming({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.gaming.enable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.gaming.enable.error'
              )
            )
          })
        break
      default:
        break
    }
  }

  handleDisableRestaurantOwner = feature => {
    const { restaurantOwner, updateRestaurantOwner } = this.props
    switch (feature) {
      case 'statistics':
        this.props.actions
          .disableStatistics({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(I18n.t('pages.statistics.api.disable.success'))
          })
          .catch(() => {
            message.error(I18n.t('pages.statistics.api.disable.error'))
          })
        break
      case 'events':
        this.props.actions
          .disableEvents({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.events.disable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.events.disable.error'
              )
            )
          })
        break
      case 'booking':
        this.props.actions
          .disableBooking({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.booking.disable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.booking.disable.error'
              )
            )
          })
        break
      case 'order':
        this.props.actions
          .disableOrder({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.order.disable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.order.disable.error'
              )
            )
          })
        break
      case 'multi_photo':
        this.props.actions
          .disableMultiPhoto({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.multiPhoto.disable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.multiPhoto.disable.error'
              )
            )
          })
        break
      case 'auto_delete':
        this.props.actions
          .disableAutoDelete({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.autoDelete.disable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.autoDelete.disable.error'
              )
            )
          })
        break
      case 'conversation':
        this.props.actions
          .disableConversation({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.conversation.disable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.conversation.disable.error'
              )
            )
          })
        break
      case 'gaming':
        this.props.actions
          .disableGaming({
            id: get(restaurantOwner, 'establishments[0].id'),
            restaurantOwnerId: restaurantOwner.id
          })
          .then(() => {
            updateRestaurantOwner()
            message.success(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.gaming.disable.success'
              )
            )
          })
          .catch(() => {
            message.error(
              I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.api.gaming.disable.error'
              )
            )
          })
        break
      default:
        break
    }
  }

  render() {
    const { restaurantOwner, menuBoards, isFetchingMenuBoards } = this.props
    const { visible, childrenDrawer, openedModal } = this.state

    return (
      <Drawer
        width={900}
        placement='right'
        onClose={this.handleClose}
        visible={visible}
        className='restaurantOwner-profile'
      >
        {/* Ouverture de la modale pour afficher le carousel */}
        <ImageCarouselModal
          width={1000}
          openedModal={openedModal === 'openCarousel'}
          onCancel={this.handleCancel}
        />

        {/* Deuxième Drawer pour les photos */}
        <Drawer
          className='restaurantOwner-profile'
          width={500}
          closable={false}
          onClose={this.onChildrenDrawerClose}
          visible={childrenDrawer}
        >
          <main className='restaurantOwner-profile-description'>
            {/* Informations de l'établissement */}
            <section className='restaurantOwner-profile-description-group'>
              {/* Image du restaurant */}
              <header className='restaurantOwner-profile-header'>
                <h3>
                  {I18n.t(
                    'pages.restaurantOwners.components.drawer.description.menuBoard.title'
                  )}
                </h3>
              </header>
              {this.renderPictureDescriptionItem('picture', menuBoards)}
            </section>
          </main>
        </Drawer>

        <div className='restaurantOwner-profile-details'>
          <div className='restaurantOwner-profile-details-information'>
            <div className='restaurantOwner-profile-details-information-user'>
              {/* Nom du restaurateur */}
              <h2>{get(restaurantOwner, 'fullname')}</h2>

              <a href={`mailto:${get(restaurantOwner, 'email')}`}>
                {get(restaurantOwner, 'email')}
              </a>
            </div>
            <div className='restaurantOwner-profile-details-information-dates'>
              <p>
                {I18n.t(
                  'pages.restaurantOwners.components.drawer.description.createdAt'
                )}
                {get(restaurantOwner, 'createdAt') !== undefined
                  ? I18n.t('date.formats.longDay', {
                      date: moment(get(restaurantOwner, 'createdAt')).toDate()
                    })
                  : '-'}
              </p>
              <p>
                {I18n.t(
                  'pages.restaurantOwners.components.drawer.description.updatedAt'
                )}
                {get(restaurantOwner, 'updatedAt') !== undefined
                  ? I18n.t('date.formats.longDay', {
                      date: moment(get(restaurantOwner, 'updatedAt')).toDate()
                    })
                  : '-'}
              </p>
            </div>
          </div>
          <div className='restaurantOwner-profile-details-actions'>
            {/* Afficher les ardoises */}
            <Button
              type='primary'
              htmlType='submit'
              loading={isFetchingMenuBoards}
              onClick={this.openMenuBoards}
              className='restaurantOwner-profile-details-information-btn'
            >
              {I18n.t(
                'pages.restaurantOwners.components.drawer.description.menuBoard.button.viewMenuBoards'
              )}
            </Button>

            {/* Désabonner le restaurateur */}
            {get(restaurantOwner, 'establishments[0].subscribeState') ===
              'STRIPE_OK' && (
              <Button
                type='danger'
                htmlType='submit'
                onClick={() => this.askDeleteSubscription(restaurantOwner)}
                className='restaurantOwner-profile-details-information-btn'
              >
                {I18n.t(
                  'pages.restaurantOwners.components.drawer.description.menuBoard.button.delete'
                )}
              </Button>
            )}

            {get(restaurantOwner, 'establishments[0].subscribeState') ===
              'NO_PAYMENT' && (
              <Button
                type='default'
                htmlType='submit'
                onClick={() => this.askForceEnableSubscription(restaurantOwner)}
                className='restaurantOwner-profile-details-information-btn'
              >
                {I18n.t(
                  'pages.restaurantOwners.components.drawer.description.menuBoard.button.enable'
                )}
              </Button>
            )}

            {/* Désabonner le restaurateur */}
            {get(restaurantOwner, 'establishments[0].subscribeState') ===
              'FORCE_OK' && (
              <Button
                type='danger'
                htmlType='submit'
                onClick={() =>
                  this.askForceDisableSubscription(restaurantOwner)
                }
                className='restaurantOwner-profile-details-information-btn'
              >
                {I18n.t(
                  'pages.restaurantOwners.components.drawer.description.menuBoard.button.disable'
                )}
              </Button>
            )}
          </div>
        </div>

        <List
          size='small'
          grid={{ column: 2 }}
          bordered
          header={
            <Typography.Text className='restaurantOwner-profile-details-permissions-title'>
              {I18n.t(
                'pages.restaurantOwners.components.drawer.permissions.title'
              )}
            </Typography.Text>
          }
          dataSource={[
            {
              name: 'statistics',
              value:
                indexOf(
                  get(restaurantOwner, 'permissions', []),
                  'api.statistics_access'
                ) !== -1
            },
            {
              name: 'events',
              value:
                indexOf(
                  get(restaurantOwner, 'permissions', []),
                  'api.events_access'
                ) !== -1
            },
            {
              name: 'booking',
              value:
                indexOf(
                  get(restaurantOwner, 'permissions', []),
                  'api.booking_access'
                ) !== -1
            },
            {
              name: 'order',
              value:
                indexOf(
                  get(restaurantOwner, 'permissions', []),
                  'api.order_access'
                ) !== -1
            },
            {
              name: 'multi_photo',
              value:
                indexOf(
                  get(restaurantOwner, 'permissions', []),
                  'api.multi_photo_access'
                ) !== -1
            },
            {
              name: 'auto_delete',
              value:
                indexOf(
                  get(restaurantOwner, 'permissions', []),
                  'api.auto_delete_access'
                ) !== -1
            },
            {
              name: 'conversation',
              value:
                indexOf(
                  get(restaurantOwner, 'permissions', []),
                  'api.conversation_access'
                ) !== -1
            },
            {
              name: 'gaming',
              value:
                indexOf(
                  get(restaurantOwner, 'permissions', []),
                  'api.gaming_access'
                ) !== -1
            }
          ]}
          renderItem={item => (
            <List.Item>
              {item.value ? (
                <Icon
                  type='check-square'
                  className='restaurantOwner-profile-details-permissions-list-icon restaurantOwner-profile-details-permissions-list-icon-enable'
                />
              ) : (
                <Icon
                  type='close-square'
                  className='restaurantOwner-profile-details-permissions-list-icon restaurantOwner-profile-details-permissions-list-icon-disable'
                />
              )}
              <Typography.Text className='restaurantOwner-profile-details-permissions-list-text'>
                {I18n.t(
                  'pages.restaurantOwners.components.drawer.permissions.type.' +
                    item.name
                )}
              </Typography.Text>
              <Button
                type={!item.value ? 'primary' : 'danger'}
                onClick={() => {
                  if (!item.value) {
                    this.handleEnableRestaurantOwner(item.name)
                  } else {
                    this.handleDisableRestaurantOwner(item.name)
                  }
                }}
              >
                {I18n.t(
                  'pages.restaurantOwners.components.drawer.permissions.actions.' +
                    (!item.value ? 'enable' : 'disable')
                )}
              </Button>
            </List.Item>
          )}
          className='restaurantOwner-profile-details-permissions-list'
        />

        {/* Informations complémentaires */}
        <main className='restaurantOwner-profile-description'>
          {/* Informations de l'établissement */}
          <section className='restaurantOwner-profile-description-group'>
            <header className='restaurantOwner-profile-header'>
              {get(restaurantOwner, 'establishments[0].logo') && (
                <Avatar
                  src={get(restaurantOwner, 'establishments[0].logo')}
                  icon='user'
                  shape='square'
                />
              )}
              <h3>{get(restaurantOwner, 'establishments[0].name')}</h3>
            </header>

            {/* Type de l'établissement */}
            {this.renderDescriptionItem(
              'establishmentType',
              get(restaurantOwner, 'establishments[0].type.name')
            )}

            {/* Type de nourriture */}
            {this.renderFoodTypeDescriptionItem(
              'foodType',
              get(restaurantOwner, 'establishments[0].foodType')
            )}

            {/* Description de l'établissement */}
            {this.renderDescriptionItem(
              'description',
              get(restaurantOwner, 'establishments[0].description')
            )}

            <h4>
              {I18n.t(
                'pages.restaurantOwners.components.drawer.description.separator.moreInformations'
              )}
            </h4>
            <section className='restaurantOwner-profile-description-group'>
              {/* Affichage custom de l'adresse complète */}
              {this.renderCustomDescriptionItem('fullAddress', restaurantOwner)}

              {/* Jours d'ouverture de l'établissement */}
              {this.renderBusinessHoursDescriptionItem(
                'businessHours',
                get(restaurantOwner, 'establishments[0].businessHours')
              )}

              {/* Numéro de téléphone */}
              {this.renderDescriptionItem(
                'phoneNumber',
                get(restaurantOwner, 'establishments[0].phoneNumber')
              )}

              {/* Url des réseaux sociaux */}
              {this.renderUrlDescriptionItem(
                'urls',
                get(restaurantOwner, 'establishments[0]')
              )}
            </section>

            <h4>
              {I18n.t(
                'pages.restaurantOwners.components.drawer.description.separator.otherInformations'
              )}
            </h4>
            <section className='restaurantOwner-profile-description-group'>
              {/* DUNS */}
              {this.renderDescriptionItem(
                'duns',
                get(restaurantOwner, 'establishments[0].DUNS')
              )}
              {/* Favorites count */}
              {this.renderDescriptionItem(
                'favoritesCount',
                get(restaurantOwner, 'establishments[0].favoritesCount', 0)
              )}
            </section>
            <h4>
              {I18n.t(
                'pages.restaurantOwners.components.drawer.description.separator.links'
              )}
            </h4>
            <section className='restaurantOwner-profile-description-group'>
              {/* Menu */}
              {this.renderLinkItem('menu', this.getMenuLink(restaurantOwner))}

              {/* QRCode */}
              {this.renderLinkItem(
                'qrcode',
                this.getQrCodeLink(restaurantOwner)
              )}

              {/* Slate */}
              {this.renderLinkItem('slate', this.getSlateLink(restaurantOwner))}
            </section>
          </section>
        </main>
      </Drawer>
    )
  }
}

const mapStateToProps = state => {
  return {
    menuBoards: get(state, 'restaurantOwner.menuboards', []),
    isFetchingMenuBoards: get(state, 'restaurantOwner.isFetchingMenuboards')
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...RestaurantOwnerActions,
      ...statisticsActions,
      ...eventsActions,
      ...bookingActions,
      ...orderActions,
      ...multiPhotoActions,
      ...autoDeleteActions,
      ...conversationActions,
      ...gamingActions
    },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestaurantOwnerDrawer)
