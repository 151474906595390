import { createResource } from 'redux-rest-resource'
import { baseURL } from './'

export const { types, actions, rootReducer } = createResource({
  name: 'profile',
  url: `${baseURL}`,
  credentials: 'include',
  actions: {
    get: {
      method: 'GET',
      gerundName: 'getting',
      url: './auth/me'
    },
    update: {
      method: 'PATCH',
      gerundName: 'updating',
      url: './auth/me',
      reduce: ({ item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        // Si la requête est terminée
        if (status === 'resolved') {
          // Mise à jour du local item
          item = updatedItem
        }

        return { ...state, item, isUpdating }
      }
    },
    updateAvatar: {
      method: 'PATCH',
      gerundName: 'updating',
      url: './auth/me',
      reduce: ({ item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        // Si la requête est terminée
        if (status === 'resolved') {
          // Mise à jour du local item
          item = updatedItem
        }

        return { ...state, item, isUpdating }
      }
    },
    changePassword: {
      method: 'POST',
      gerundName: 'updating',
      url: './auth/password'
    }
  }
})
