import I18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ReduxDetector from 'i18next-redux-languagedetector'

import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fr'
import date from 'I18n/date'

const LngDetector = new LanguageDetector()
LngDetector.addDetector(ReduxDetector)

const commonConfig = {
  interpolation: {
    // React already does escaping
    escapeValue: false,
    format(value, format, locale) {
      if (format === 'currency') {
        return new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: 'EUR'
        }).format(value)
      } else if (value instanceof Date) {
        return date.format(value, format)
      } else if (value === undefined) {
        return format
      }
      return value
    }
  },
  react: {
    wait: true
  },
  resources: {
    fr: {
      translation: require('./languages/fr.json')
    },
    en: {
      translation: require('./languages/english.json')
    }
  },
  fallbackLng: 'fr',
  whitelist: ['en', 'fr']
}

// Configurations
export function configureI18nWithRedux({ redux, config }) {
  I18n.use(LngDetector).init({
    ...commonConfig,
    ...config,
    detection: {
      // order and from where user language should be detected
      order: ['redux'],

      // keys or params to lookup language from
      lookupRedux: redux.lookupRedux,
      cacheUserLanguageRedux: redux.cacheUserLanguageRedux,

      // cache user language on
      caches: ['redux'],
      excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
    },
    interpolation: {
      format: function(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase()
        if (value instanceof Date) {
          return moment(value).format(format)
        }
        return value
      }
    }
  })

  // Locales moment js
  const locale = I18n.language || window.localStorage.i18nextLng

  // Set the locales for momentJS
  moment.locale(locale)

  return I18n
}

I18n.use(LanguageDetector).init({
  ...commonConfig,
  detection: {
    // order and from where user language should be detected
    order: [
      'querystring',
      'cookie',
      'localStorage',
      'navigator',
      'htmlTag',
      'path',
      'subdomain'
    ],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement
  }
})

// Locales moment js
const locale = I18n.language || window.localStorage.i18nextLng

date.init(locale)
// Set the locales for momentJS
// moment.locale(locale)

export default I18n
