import { put, select, takeLatest } from 'redux-saga/effects'
import {
  types as AutoDeleteTypes,
  actions as autoDeleteActions
} from 'Resources/AutoDeleteResource'
import { actions as restaurantOwnerActions } from 'Resources/RestaurantOwnerResource'

export function* autoDeleteSagas() {
  yield takeLatest(
    action =>
      action.type === AutoDeleteTypes.ENABLE_AUTO_DELETE &&
      action.status === 'resolved',
    enableAutoDelete
  )
  yield takeLatest(
    action =>
      action.type === AutoDeleteTypes.DISABLE_AUTO_DELETE &&
      action.status === 'resolved',
    disableAutoDelete
  )
}

function* enableAutoDelete(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions.push('api.auto_delete_access')
  yield put(autoDeleteActions.setRestaurantOwnerAutoDelete(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}

function* disableAutoDelete(action) {
  const restaurantOwners = yield select(
    ({ restaurantOwner }) => restaurantOwner.items
  )
  const id = action.context.restaurantOwnerId
  const restaurantOwner = restaurantOwners.find(
    searchRestaurantOwner => searchRestaurantOwner.id === id
  )
  restaurantOwner.permissions = restaurantOwner.permissions.filter(
    permission => permission !== 'api.auto_delete_access'
  )
  yield put(autoDeleteActions.setRestaurantOwnerAutoDelete(restaurantOwner))
  yield put(restaurantOwnerActions.setRestaurantOwner(restaurantOwner))
}
